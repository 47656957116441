import React, { useEffect, useState } from 'react';
import styles from './marketplace.module.css';
import MainNavBar from '../../components/navigation/main-nav';
import Filters from '../../components/feeds/filter';
import PostContents from '../../components/feeds/posts';
import Followers from '../../components/feeds/followers';
import { useCookies } from 'react-cookie';
import APIContents from '../../api/api';
import ClipLoader from "react-spinners/ClipLoader";


import ReactGA from "react-ga";





const MarketPlace = ({props, filterDisplayStyle, setFilterDisplayStyle}) => {

    useEffect(() => {
        // Track page view
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']

    const [followers, setFollowers] = useState([])
    const [userCity, setUserCity] = useState('')
    const [userState, setUserState] = useState('')


    useEffect(()=> {
     if (props) {
        setUserState(props["userLocation"].regionName)
        setUserCity(props["userLocation"].city)
        // console.log(userCity)
    }
      
    }, [props])

    // if (props.props) {
        // setUserCity(props.props["userLocation"].city)
    // }

    function follow(postUser, currUser){
        const newFollower = { 
            follower: currUser, 
            isFollowing: true,
            user: {
                username: postUser, 
            },
        }
        setFollowers(prev => [...prev, newFollower]);
        console.log(followers)
        console.log(newFollower.user.username)

        const details = {
        user: postUser,
        follower: currUser

    }
    APIContents.Follow(details, auth)
    .then(resp=> console.log(resp))
    props.refresh()
    
}



    
    useEffect(()=> {
        APIContents.getFollowers(auth)
        .then(resp => setFollowers(resp))
    }, [auth])

    const [filters, setFilters ] = useState({
        category: '',
        min: '',
        max: '',
        state: '',
        city: '',
        delivery: '',

    });


    const [user, setUser] = useState()

    let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']

    useEffect(()=> {
        APIContents.UserProfile(loggedInUser)
        .then(resp => setUser(resp))
    }, [loggedInUser])


    // const deletePost =(postid)=> {
    //     console.log('clicked')
    //     const deleteNow = {
    //         postid: postid
    //     }

    //     APIContents.DeletePost(deleteNow, auth)
    //     .then(resp=>console.log(resp))
    //     props.props['refresh']()
        
        
    // }

    document.title = 'marketplace'
    return (
        <>
            <MainNavBar props = {props} filterDisplayStyle = {filterDisplayStyle} setFilterDisplayStyle = {setFilterDisplayStyle} />
        {
                            props["loading"] ? 
                            <div style={{width: "100vw", display: "flex", height: "80vh", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                            <ClipLoader
                            // color={color}
                            loading={props["loading"]}
                            // cssOverride={override}
                            size={150}
                            // style={{margin: "auto"}}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                            </div>
                            : 
            <>
            <div className={styles.mainContainer} style={{ boxSizing: 'border-box' }} id='marketplace'>
                <div className={styles.filterContainer} id='filterContainer' style={{margin: "0px"}}>
                <Filters filters= {filters} setFilters = {setFilters} currentCity = {userCity} setUserCity = {setUserCity} filterDisplayStyle = {filterDisplayStyle} setFilterDisplayStyle = {setFilterDisplayStyle} />
                </div>
                {/* <div className='postfeeds-container' style={{margin: "0px"}}> */}

            <PostContents filters= {filters} props = {props} followers ={followers} follow={follow} MainloggedInUser = {user === undefined ? '' : user } />
            {/* </div> */}
            <div className='followers-container' style={{margin: "0px"}}>

            <Followers follow={follow} props={props} followers={followers} />
            </div>
 
            </div>
</>
        }
        </>
    );
}

export default MarketPlace;