import React, { useEffect, useRef, useState } from 'react';
import MainNavBar from '../../components/navigation/second-nav';
import './profile.modules.css';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import APIContents from '../../api/api';
import { Carousel } from 'react-responsive-carousel';
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from '../404/notfound';
import Wallet from '../../components/wallet/wallet';
import Modal from 'react-modal';
import SinglePostPopup from '../../components/singlepost/singlePost';

import ReactGA from 'react-ga';

const Profile = (props) => {

	
useEffect(() => {
    // Track pageview when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Optionally, you can also set a custom page title for each page
    ReactGA.set({ title: 'Your Page Title' });
  }, []);

	// const BASE_URL = 'http://192.168.8.115:8000'
	// const BASE_URL = 'http://192.168.68.118:8000'

	// const BASE_URL = 'http://192.168.8.117:8000'
	// const BASE_URL = 'https://nearbyvendor.onrender.com'
	const BASE_URL = 'https://source.nearbyvendor.com'


	// const BASE_URL = 'http://192.168.1.157:8000'
	const { username } = useParams()
		
	let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']

	
	let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']



	// const [users, setUsers] = useState([])

	const [isAuth, setIsAuth] = useState(false)
	
	const [userProfile, setUserProfile] = useState(null)
	const [Grouped, setGrouped] = useState(null)
	const [indicator, setIndicator] = useState(false)
	const [isFollowing, setIsFollowing] = useState(false)
	const [loading, setLoading] = useState(false)

    const navigate = useNavigate()




	// const parsedUserInfo = JSON.parse(userProfile);

	useEffect(()=> {

		setIsAuth(auth !==  undefined && auth !== null);
		
	}, []);

	const [userStatus, setUserStatus] = useState(false)
	

	// useEffect(()=> {
	// 	APIContents.Profiles(auth)
	// 	.then(resp=> {
	// 		// setUsers(resp);		
	// 	const currentUser = resp.find(user => user.username === username )
	// 	setUserProfile(currentUser)
	// 	})
	// 	.catch(error => {
	// 		console.log('error fetching data: ', error)
	// 	})
	// }, []);


 
	useEffect(() => {
		setLoading(true)
		try {
			APIContents.GetCurrentUser(auth, username)
			.then((resp)=> {
				setUserProfile(resp);
				// const data = JSON.stringify(resp);
				// const data_json = JSON.parse(data);
				// setUserProfile(data_json)
				
				const data = resp.currentuser.posts || []
	
	
				const temp = {}
				data. forEach((groups, post) => {
					const category = groups.category;
					if (!temp[category]) {
						temp[category] = [groups]
					 } else{
						temp[category].push(groups)
						// category[groups.category] = [];
					 }
				})
				setGrouped(temp);
				setLoading(false)
	
				const checkFollowing = resp.currentuser.followers.find(follower => follower.follower === loggedInUser)
				if (checkFollowing !== undefined && checkFollowing !== '' && checkFollowing !== null){
					setIsFollowing(true)
				} else {
					setIsFollowing(false)
				}
			
			})
	
		} catch {

		}
	
	
	}, [username, indicator])


	document.title = `profile - ${username}`

	// const data = JSON.stringify(userProfile);


    // APIContents.Follow(details, auth)
    // .then(resp=> console.log(resp))
    // props.props.refresh()

	const [statusCookie, setStatusCookie] = useCookies(['onlineStatus'])


	let color;
	
	switch (props.userStatus){
		case "online": 
			color = 'green';
			break;
		case 'away':
			color = 'orange';
			break;
		case 'offline':
			color = 'red';
			break;
		default:
			color = 'transparent';

	}

	const setStatus = (e) => {
		props.setUserStatus(e.currentTarget.innerText.replace('.', '').trim())
		setShowOptions(!showOptions)
		setStatusCookie('onlineStatus', e.currentTarget.innerText.replace('.', '').trim())
	}
    



	// console.log(userProfile.currentuser.followers[1])

	

	const CurrUserPosts = props.props['posts'].filter

    const formatedNumber = (number)=> {
        return number.toLocaleString()
    }

	
	function follow(profileUser, currUser){

        const details = {
        user: profileUser,
        follower: currUser

    }
	APIContents.Follow(details, auth)
    .then(resp=> console.log(resp))
    props.props.refresh()
	setIndicator(!indicator)
    
}


	const [showOptions, setShowOptions] = useState(false)

	// wallet pop up and close 

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
	  setIsModalOpen(true);
	};
  
	const closeModal = () => {
	  setIsModalOpen(false);
	};

	  

	// single post pop up 
	const [singlePostContent, setSinglePostContent] = useState({})
    const [singlePostPopUp, setSinglePostPopUp] = useState(false)

	const closeSinglePostModal = () => {
		setSinglePostPopUp(false);
	  };


	console.log(singlePostContent)

	const SinlgePostPopUp = (post) =>{
		// setSinglePostPopUp(!singlePostPopUp)
		console.log('singlePostPopUp')
	}

	const preventDef = (e)=> {
		e.preventDefault();
	}

	const ChatUser = (e) =>{

		const receiver = userProfile.currentuser.user.username
		console.log(receiver)

		const data = {
			receiver : receiver
		}
		APIContents.Chat(data, auth)
		.then((resp) => {
			if (resp.uniquecode){
				navigate(`/chats/${resp.uniquecode}`)
			}
		})
	}


	return (
    <>

        <MainNavBar/>

		{
			loading ? 
			<div style={{width: "100vw", display: "flex", height: "80vh", margin: "auto", alignItems: "center", justifyContent: "center"}}>
			<ClipLoader
			// color={color}
			loading={loading}
			// cssOverride={override}
			size={150}
			// style={{margin: "auto"}}
			aria-label="Loading Spinner"
			data-testid="loader"
		  />

			</div>
:
			userProfile !== undefined && userProfile !== null ? (
				<>
				<div id='profile-profile' className="profile">
				<div className="top-profile">
				<div className="header-profile">
				<div className="images-item">
				<img id="cover-picx" src={userProfile.currentuser.coverimg ? BASE_URL + '/media/' + userProfile.currentuser.coverimg : ''} alt=""/>
				<img id="profile-picx" src={userProfile.currentuser.profileimg ? BASE_URL + '/media/' +  userProfile.currentuser.profileimg : ''} alt=""/>
				
				{/* <img id="cover-picx" src={ BASE_URL + '/media/' +  userProfile.profile['coverimg'] || ''} alt=""/> */}
				{/* <img id="profile-picx" src={ BASE_URL + '/media/' +  userProfile.profile['profileimg'] || ''}  alt=""/> */}
			</div>
			<div className="user-info">
				<div className="top">
					<h2 id="marketname">
						{
								(userProfile.currentuser.market_info === null || userProfile.currentuser.market_info === undefined || (userProfile.currentuser?.market_info?.marketname === null || userProfile.currentuser?.market_info?.marketname === '')) ? 
								(( userProfile.currentuser.user.last_name !== '' || userProfile.currentuser.user.first_name !== '' ) ? `${userProfile.currentuser?.user.first_name} ${userProfile.currentuser?.user.last_name}` : userProfile.currentuser.user.username)
								: userProfile.currentuser?.market_info?.marketname

						// userProfile.currentuser?.market_info?.marketname || 'none yet'
						}  
					<img style={{width: "15px", height: "auto", marginLeft: "5px"}} src="/media/badge2.png" alt=""/>
					</h2>  
	
				</div>

				<h4 id="username">@{userProfile.currentuser.user.username || 'none yet'}</h4> 
			</div>

			<div className="post-followers-count">
				<div className="post-follow">
						<h3>{userProfile.currentuser.others.posts_length}</h3> 
						{
							userProfile.currentuser.others.posts_length <= 1 ? <p>post</p> : <p>posts</p>
						}
				</div>
				<div className="post-follow">

					<h3>{userProfile.currentuser.others.user_followers}</h3>
					{
							userProfile.currentuser.others.user_followers <= 1 ? <p>follower</p> : <p>followers</p>
						}
				</div>
				<div className="post-follow">
					<h3>{userProfile.currentuser.others.user_following}</h3>
					<p>following</p>

				</div>
			</div> 

			<div className="follow-editprofile">
				{
					isAuth ? 
					<>
					{
					 loggedInUser ===  username ?
						<>
									<div className='left-profile-menu' >
									<button onClick={()=> setShowOptions(!showOptions)} className="fol">
										{
											// props.userStatus === 'online' ?
											 <>
											<li style={{
												display: "flex",
												height: "10px",
												alignItems: "center"

											}} className="">
												<span style={{
													fontSize: '65px',
													margin: '0',
													top: '-17px',
													position: 'relative',
													color
													}}>.</span>
												{
													props.userStatus
												}
												
												</li>
											{/* </> :  <>
											<span style={{
													fontSize: '65px',
													margin: '0',
													top: '-20px',
													position: 'relative',
													color: 'red'}}>.</span>
											Offline */}
											</>
										}
										</button>
										{ showOptions && (
											<ul className={['options-dropdown']}>
											<li onClick={(e) => setStatus(e)} className="">
												<span style={{
													fontSize: '65px',
													margin: '0',
													top: '-20px',
													position: 'relative',
													color: 'green'}}>.</span>
												online</li>
											<li onClick={(e) => setStatus(e)} className="">
											<span style={{
													fontSize: '65px',
													margin: '0',
													top: '-20px',
													position: 'relative',
													color: 'orange'}}>.</span>

												away</li>
											<li onClick={(e) => setStatus(e)} className="">
											<span style={{
													fontSize: '65px',
													margin: '0',
													top: '-20px',
													position: 'relative',
													color: 'red'}}>.</span>

												offline</li>
										</ul>
											
										)
										}

									</div>
									<button onClick={()=> openModal()} className="fol">Wallet</button>
									<Modal
										isOpen={isModalOpen}
										onRequestClose={closeModal}
										contentLabel="Wallet"
									>
										<Wallet closeModal={closeModal} />
									</Modal>
						</>
						: <>
						{/* <a href={''} className="comment-button"> */}
							<button onClick={(e)=> ChatUser(e)} className="fol">
								Message 
						</button>
					{/* </a> */}
							<button onClick={()=> follow(username, loggedInUser)} className="fol">
								
								{
									!isFollowing ? <>Follow</> : <>Unfollow</>
								}
								
						</button>

						</>
					}

					</> :
					<>
					</>

				}

			</div>

		</div>
	</div>
        </div>
		<div className="postContent" style={{marginBottom: '20px'}}>
	<h3>
		
		{
								(userProfile.currentuser.market_info === null || userProfile.currentuser.market_info === undefined || (userProfile.currentuser?.market_info?.marketname === null || userProfile.currentuser?.market_info?.marketname === '')) ? 
								(( userProfile.currentuser.user.last_name !== '' || userProfile.currentuser.user.first_name !== '' ) ? `${userProfile.currentuser?.user.first_name} ${userProfile.currentuser?.user.last_name}` : userProfile.currentuser.user.username)
								: userProfile.currentuser?.market_info?.marketname
		}'s Catalog</h3> 

	<div className="products-list">
		<div className="categories">

			{

				// userProfile.currentuser.posts.reduce((group, post) => {

				// })
				Object.keys(Grouped) && Object.keys(Grouped).map((group, index) => {
					return (
						<>
						<div key={index} className="category-name">
							<h4>{group} Category</h4>
		
						</div>
							<div className="category-item">
								{
									Grouped && Grouped[group].map((post, index)=> (
										<div key={index} className="category">
											<div>
												{
												post.images.length === 1 ? (
												<a
												onClick={(e) => {
													e.preventDefault(); // Prevent default behavior
													e.stopPropagation(); // Prevent event propagation
													setSinglePostPopUp(!singlePostPopUp);
													setSinglePostContent(Grouped[group][index])
												  }}
													>
												<img className="strip" src={BASE_URL + '/media/' + post.images[0].image} alt=""/>
												</a>
												) : (
													<>
													<Carousel
													autoPlay={true}
													//   autoPlay={index === selectedPostIndex}
													//   onClick={handlePostChange}
													showThumbs={false}
													showArrows={true}
													showStatus={false}
													infiniteLoo={true}
													dynamicHeight={true}
													interval={3500} // Auto slide every 1.5 seconds
													>
													{post.images.map((item, index) => (
														<div key={index} onClick={()=> {
															setSinglePostPopUp(!singlePostPopUp);
															setSinglePostContent(Grouped[group][index])
														}} style={{margin: 'auto'}}>
														<img
															className="carousel-image"
															src={BASE_URL + '/media/' + item.image}
															alt={`Img ${index}`}
															style={{width: "130px", height: "130px", borderRadius: '2rem'}}
															onClick={(e) => {
																e.preventDefault(); // Prevent default behavior
																e.stopPropagation(); // Prevent event propagation
															}}
															  />
														</div>
													))}
													</Carousel>

													</>

													// <Carousel
											// 		autoPlay={true}
											// 		//   autoPlay={index === selectedPostIndex}
											// 		//   onClick={handlePostChange}
											// 		showThumbs={false}
											// 		showArrows={true}
											// 		showStatus={false}
											// 		infiniteLoo={true}
											// 		dynamicHeight={true}
											// 		interval={3500} // Auto slide every 1.5 seconds
											// 		>
											// 		{post.images.map((item, index) => (
											// 			// <div >
											// 			<img key={index}
											// 				className="carousel-image"
											// 				src={BASE_URL + '/media/' + item.image}
											// 				alt={`Img ${index}`}
											// 				style={{width: "130px", borderRadius: "2rem", height: "130px"}}
											// 				/>
											// 			// </div>
											// 		))}
											// 		</Carousel>
												)
													
												}
												
													<h4>{post.title} - N {formatedNumber(post.price)}</h4>
	
										</div>
	
									</div>	

									))
								}
								</div>		

						</>
					)
				})
			}






			</div>
		</div>
		</div>
				
				</>
			) : (
				<>
				<NotFound />
				</>
			)
		}            
			{
                (singlePostPopUp && singlePostContent !== undefined) ?
                <>
				<Modal
					isOpen={singlePostPopUp}
					onRequestClose={closeSinglePostModal}
					contentLabel="Post Pop UP"
				>
							<>
							{
							singlePostContent.images.length === 1 ? (
							<img className="strip" style={{width: "70vw", maxWidth: '500px'}} src={BASE_URL + '/media/' + singlePostContent.images[0].image} alt=""/>
							) : (
								<Carousel
								autoPlay={true}
								//   autoPlay={index === selectedPostIndex}
								//   onClick={handlePostChange}
								showThumbs={false}
								showArrows={true}
								showStatus={false}
								infiniteLoo={true}
								dynamicHeight={true}
								interval={3500} // Auto slide every 1.5 seconds
								>
								{singlePostContent.images.map((item, index) => (
									<div key={index} style={{margin: 'auto'}}>
									<img
										className="carousel-image"
										src={BASE_URL + '/media/' + item.image}
										alt={`Img ${index}`}
										style={{width: "70vw", maxWidth: '500px'}}
										onClick={(e) => {
											e.preventDefault(); // Prevent default behavior
											e.stopPropagation(); // Prevent event propagation
										}}
											/>
									</div>
								))}
								</Carousel>
							)
								
							}
							
							</>
				</Modal>

                {/* <SinglePostPopup singlePostContent={singlePostContent} setSinglePostPopUp={setSinglePostPopUp} /> */}
                </> : <></>
            }

        </>
    );
	}
export default Profile;
