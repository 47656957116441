import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './search.css'; // Import the CSS file
import MainNavBar from '../../components/navigation/second-nav';

const SearchResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParam = new URLSearchParams(location.search)

  const user = searchParam.get('user')
  const searchResult = location.state?.details || [];

  console.log(searchResult[1])

  const handleCardClick = (user) => {
      navigate(`/profile/${user}`)
  }
  return (
    <>
    <MainNavBar />
    <div className="search-result-container">
      <h2>Search Results for "{user }"</h2>
      {searchResult.length > 0 ? (
        searchResult.map((item, index) => (
          <div key={index} className="search-result-card" onClick={() => handleCardClick(item.user.username)}>
          {item.user.profile && item.user.profile.profileimg && (
            <img src={item.user.profile.profileimg} alt={`Profile of ${item.user.username}`} />
          )}
          <div className="user-info">
            <div className="user-name">
              {item.user.profile && (item.user.profile.marketname || `${item.user.first_name} ${item.user.last_name}`)}
            </div>
            <div className="user-username">@{item.user.username}</div>
            <div className="user-details">
              <p>Email: {item.user.email}</p>
              {item.user.profile && <p>Phone: {item.user.profile.phone}</p>}
            </div>
          </div>
        </div>      ))
      ) : (
        <p>No users found.</p>
      )}
    </div></>
  );
};

export default SearchResultPage;
