import React, { useEffect, useRef, useState } from "react";
import MainNavBar from "../../components/navigation/second-nav";
import "./chatbox.css";
import APIContents from "../../api/api";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";
import moment from 'moment';
import { Form } from "react-router-dom";

const ChatBody = (props) => {


  const overLayStyle = {
    content: '',
    backgroundColor : "white",
    zIndex: -1,
}


  const formatTime = (timestamp) =>{
    // moment.utc(item.created).local().startOf('seconds').fromNow()

    const now = moment()
    const diffSec = now.diff(timestamp, 'seconds');

    if (diffSec < 60) {
      return 'just now';

    } else {
      return moment(timestamp).fromNow()
    }
  }


  const { uniquecode } = useParams();

  const [chatInfo, setChatInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const BASE_URL = 'https://source.nearbyvendor.com';
  // const BASE_URL = 'http://192.168.8.117:8000'


  let loggedInUserToken = useCookies('username');
  let loggedInUser = loggedInUserToken[0]['username'];

  let token = useCookies('loginToken');
  let auth = token[0]['loginToken'];




  const chatContainerRef = useRef(null);

  useEffect(()=>{
  if (chatContainerRef.current){

    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages])


  useEffect(() => {
    try {
      APIContents.GetMessages(auth, uniquecode).then((resp) => {
        setChatInfo(resp['chat_room']); 
        setMessages(resp['data']);
      });
    } catch (e) {
      console.error(e);
    }


    // // Set up an interval to call the API every second
    // let interval = setInterval(() => {
    // }, 1000);

    // // Clear the interval when the component unmounts
    // return () => {
    //   clearInterval(interval);
    // };
  }, [auth, uniquecode, newMessage]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    // console.log(newMessage)
    const formData = new FormData()
    formData.append("message", newMessage)
    formData.append("uniquecode", uniquecode)

    const body = {
      message : newMessage,
      // sender : loggedInUser,
      uniquecode : uniquecode
    }
      setNewMessage('')
      APIContents.SendMessage(auth,body, uniquecode)
      .then(resp => {
      })
    // }
    // catch(error) {
    //   console.log(error)
    // }



    // if (newMessage.trim() !== '') {
    //   setMessages([...messages, { sender: 'You', message: newMessage }]);
    //   setNewMessage('');
    // }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log('File uploaded:', file);
  };



	let color;
	
	switch (props.userStatus){
		case "online": 
			color = 'green';
			break;
		case 'away':
			color = 'orange';
			break;
		case 'offline':
			color = 'red';
			break;
		default:
			color = 'transparent';

	}

    const [user, setUser] = useState('')


  useEffect(()=> {
    if (chatInfo){
      // console.log(chatInfo.user1?.username)
      setUser((chatInfo.user1?.username === loggedInUser)? chatInfo.user1  :  chatInfo.user2)

    }
  
  }, [user, chatInfo])


  console.log(user?.profile?.wallpaper)
  return (
        <>
        <MainNavBar />

        {
                chatInfo && user && 
                <div style={
                  overLayStyle
                } className="chat-body">
                  <div 
                    style={{
                      backgroundImage : `url("${BASE_URL}${user?.profile?.wallpaper}")`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundAttachment: 'fixed',
                      position: 'relative',
                      overflow: 'hidden',
                      zIndex: "-2"
                    }}
                  
                  className="chat-body-c">
                    <div 
                    style={{backgroundColor : user?.profile?.background_color !== null ? user?.profile?.background_color : ''}}  
                    className="chat-body-outer">
                      <div className="chat-userinfo">
                        <div className="chat-convo-header">
                        <img  src={`${BASE_URL}${loggedInUser === chatInfo?.user1?.username ? chatInfo?.user2?.profile?.profileimg : chatInfo?.user1?.profile?.profileimg || ''}`} alt="Receiver Profile" />
                          <div className="chat-user-username">
                              <div style={{color : "white", fontWeight: "700"}}>
                              {loggedInUser === chatInfo?.user1?.username ? chatInfo?.user2?.username : chatInfo?.user1?.username || ''}
                              </div>
                            <span id="status" style={{
                                    border: `1px white solid`,
                                    marginLeft: '10px',
                                    backgroundColor: color,
                                    borderRadius: '2rem',
                                    padding: '4px'
                                    }}></span>
          
                          </div>
                        </div>
                        <div className="drop">
                          <img src="/static/images/menuicon.png" alt="" />
                        </div>
                        <div className="chat-container"></div>
                      </div>
                      
                    </div>
                    <p className="not_verified">Beware of Scam!!! <br></br> This user is not yet verified!!!</p>
          
                    <div
                    className="chat-body-content">
            {messages.length > 0 ? (
              <div ref={chatContainerRef} className="message" id="conversation">
                {messages.map((item, index) => ( 
                  <div key={item.id} id="message-body" className="message-body">
                  <div className={item.sender === loggedInUser ? "message-main-sender" : "message-main-receiver"}>
                    <div className={item.sender === loggedInUser ? "sender" : "receiver"}>
                      <div className="message-text">{item.message}</div>
                      <span className="message-time">{
                        formatTime(item.created_at)
                      }</span>
                    </div> 
                  </div> 
                  </div>
                ))}
              </div>
            ) : (
              <>
                <br />
                {/* <div className="message-main-receiver">
                  <div className="receiver">
                    <div className="message-text">
                      <p>Hey, <br /> Send me a hi, <br />I'm Available for business. </p>
                    </div>
                    <span className="message-time">just now</span>
                  </div>
                </div>
                <div className="message-main-sender">
                  <div className="sender">
                    <div className="message-text">
                      <p>Start typing your message... </p>
                    </div>
                    <span className="message-time">just now</span>
                  </div>
                </div> */}
              </>
            )}
          </div>
                    <div className="response">
                        <form>
                          <textarea
                            id="sendmessage"
                            name="message"
                            rows="1"
                            placeholder="Type your message"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                          ></textarea>
                          <button id="send-message" onClick={(e) => handleSendMessage(e)} type="submit">
                            SEND
                          </button>
                          {/* <button style={{ backgroundColor: 'rgb(56, 55, 55)', height: 'fit-content' }}>
                            <img
                              style={{ width: '30px', height: '16px', rotate: '0deg', marginLeft: '10px', filter: 'invert(1000%)' }}
                              src="/static/images/paynow.png"
                              alt=""
                            />
                          </button> */}
                        </form>
                      </div>
                  </div>
                </div>
                }

  
        <script>
          let scrollable = document.getElementById('conversation');
          let scrollPos = scrollable.scrollHeight - scrollable.clientHeight;
          scrollable.scrollTop = scrollPos;
        </script>
      </>
  

  );
};

export default ChatBody;


// import React, { useState } from "react";
// import MainNavBar from "../../components/navigation/second-nav";
// import "./chatbox.css";


// const ChatBody = () => {
//   const [messages, setMessages] = useState([
//     { id: 1, sender: "John", content: "Hello!" },
//     { id: 2, sender: "Jane", content: "Hi there!" },
//     // Add more messages as needed
//   ]);

//   const [newMessage, setNewMessage] = useState("");

//   const handleSendMessage = () => {
//     if (newMessage.trim() !== "") {
//       const newMessageObj = {
//         id: messages.length + 1,
//         sender: "You", // Replace with the actual sender's name
//         content: newMessage,
//       };

//       setMessages([...messages, newMessageObj]);
//       setNewMessage("");
//     }
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];

//     // Handle file upload logic, e.g., using FormData or other methods
//     console.log("File uploaded:", file);
//   };

//   return (
//     <>
//       <MainNavBar />

//       <div className="chat-container">
//         {/* Chat messages */}
//         <div className="chat-messages">
//           {messages.map((message) => (
//             <div key={message.id} className={message.sender === "You" ? "sent" : "received"}>
//               <p>{message.content}</p>
//               <span>{message.sender}</span>
//             </div>
//           ))}
//         </div>

//         {/* Message input */}
//         <div className="message-input">
//           <textarea
//             value={newMessage}
//             onChange={(e) => setNewMessage(e.target.value)}
//             placeholder="Type your message..."
//           ></textarea>
//           <button onClick={handleSendMessage}>Send</button>
//         </div>

//         {/* File upload */}
//         <div className="file-upload">
//           <input type="file" onChange={handleFileUpload} />
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChatBody;
