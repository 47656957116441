import { Route, Routes } from "react-router-dom";
import Settings from "./settings";
import ProfileSettings from "../../components/settings/profile";
import MarketPlaceSettings from "../../components/settings/marketplace";
import ChatSettings from "../../components/settings/chat";
import AboutSettings from "../../components/settings/about";
import MainNavBar from "../../components/navigation/second-nav";
import { useState, useEffect } from "react";
import APIContents from "../../api/api";
import { useCookies } from "react-cookie";
import ContactSettings from "../../components/settings/contact";

export default function SettingsRouter (){

    // const BASE_URL = 'http://192.168.8.117:8000'
    // const BASE_URL = 'http://192.168.8.116:8000'
    // const BASE_URL = 'https://nearbyvendor.onrender.com'
    const BASE_URL = 'https://source.nearbyvendor.com'

    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']
    


	
	let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']




    const [userProfile, setUserProfile] = useState("")
    const [grouped, setGrouped ] = useState('')




    useEffect(() => {
		APIContents.GetCurrentUser(auth, loggedInUser)
		.then((resp)=> {
			setUserProfile(resp);
			// const data = JSON.stringify(resp);
			// const data_json = JSON.parse(data);
			// setUserProfile(data_json)
			
			const data = resp.currentuser.posts || []


			const temp = {}
			data. forEach((groups, post) => {
				const category = groups.category;
				if (!temp[category]) {
					temp[category] = [groups]
				 } else{
					temp[category].push(groups)
					// category[groups.category] = [];
				 }
			})
			setGrouped(temp)
		
		})
	
	
	}, [])


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        phone: "",
        nationality: "",
        state: "",
        city: "",
        address: "",
        bio: "",
        // OldPassword : "",
        NewPassword : "",
        verifyNewPassword : "",
        profileimg: "",
        coverimg : "",
        wallet_is_activated: false,
        display_bio : false,

        biz_name : "",
        reg_no : "",
        biz_phone: "",
        biz_address: "",
        biz_city : "",
        biz_state : "",
        biz_country : "",

        bank_name : "",
        account_name : "",
        account_number : "",

        display_phone : false,
        allow_comments : false,
        allow_multi_image : false,

        chat_color_toggle: "",
        wallpaper : "",

        username : "",
        subject : "",
        feedback_description : "",

        ticket_number : "",
        ticket_subject : "",
        ticket_description : "",




    })

    const [image, setImage] = useState([])

    useEffect(()=> {
        if (userProfile.currentuser?.wallet !== undefined ){
            let isActive = userProfile.currentuser.wallet[0]?.is_activated || false
            if (isActive !== undefined){
                setFormData({ 
                    ...formData,
                    'wallet_is_activated' : !isActive
                });
            }
 
        }
        if (userProfile.currentuser?.display_bio !== undefined ){
            let isActive = userProfile.currentuser.display_bio
            if (isActive !== undefined){
                setFormData({ 
                    ...formData,
                    'display_bio' : !isActive
                });
            }
 
        }

        if (userProfile.currentuser?.allow_comments !== undefined ){
            let isActive = userProfile.currentuser.allow_comments
            if (isActive !== undefined){
                setFormData({ 
                    ...formData,
                    'allow_comments' : !isActive
                });
            }
 
        }
        if (userProfile.currentuser?.allow_multi_image !== undefined ){
            let isActive = userProfile.currentuser.allow_multi_image
            if (isActive !== undefined){
                setFormData({ 
                    ...formData,
                    'allow_multi_image' : !isActive
                });
            }
 
        }
   }, [])


    const UpdateDataBtn = (e) => {
        e.preventDefault();
        const id = e.target.id
        let data;
        let contentType;

        if (id === 'data-submission'){
            data = JSON.stringify({
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                profile : {
                    dob: formData.dob,
                    state: formData.state,
                    nationality: formData.nationality,
                    city: formData.city,
                    address: formData.address,
                    bio: formData.bio,
                    address: formData.address
                },
                })
                contentType = 'application/json'

        }
        else if (id === 'newpassword-submission') {
            if (formData.NewPassword === formData.verifyNewPassword){
                data = JSON.stringify({
                    password : formData.NewPassword
                })
            }
            contentType = 'application/json'
        }
        else if (id === 'profile-pic-btn') {
            // Construct FormData object for uploading image
            const formData = new FormData();
            formData.append('profileimg', image[0]); // Append the image file
    
            // Assign FormData object to data
            data = formData;
            // contentType = 'multipart/form-data'
        }
        else if (id === 'cover-pic-btn') {
            // Construct FormData object for uploading image
            const formData = new FormData();
            formData.append('coverimg', image[0]); // Append the image file
    
            // Assign FormData object to data
            data = formData;
            // contentType = 'multipart/form-data'
        }
        else if (id === 'wallet-toggle'){
            const _ = !userProfile.currentuser?.wallet[0]?.is_activated || false
            data = JSON.stringify({
                wallet: {
                    is_activated: _

                }
            })
            contentType = 'application/json'
        }
        else if (id === "display_bio-toggle") {
            data = JSON.stringify({
                    display_bio: !userProfile.currentuser.display_bio
            })
            contentType = 'application/json'   
        }
        else if (id === "vendor-details") {
            data = JSON.stringify({
                    vendorDetails: {
                        marketname : formData.biz_name,
                        reg_number : formData.reg_no,
                        phone: formData.biz_phone,
                        address: formData.biz_address,
                        city : formData.biz_city,
                        state : formData.biz_state,
                        country : formData.biz_country,
                    }
            })
            contentType = 'application/json'   
        }
        else if (id === "bank-details-update") {
            data = JSON.stringify({
                    bankDetails: {
                        bank_name : formData.bank_name,
                        account_name : formData.account_name,
                        account_number: formData.account_number,
                    }
            })
            contentType = 'application/json'   
        }
        else if (id === "display_phone-toggle") {
            data = JSON.stringify({
                    vendorDetails: {
                        display_phone : !formData.display_phone
                    }
            })
            contentType = 'application/json'   
        }
        else if (id === "allow_comment-toggle") {
            data = JSON.stringify({
                    vendorDetails: {
                        allow_comments : !formData.allow_comments
                    }
            })
            contentType = 'application/json'   
        }
        else if (id === "multi_image-toggle") {
            data = JSON.stringify({
                    vendorDetails: {
                        allow_multi_image : !formData.allow_multi_image
                    }
            })
            contentType = 'application/json'   
        }  
         else if (id === "chat-general-btn") {
            data = JSON.stringify({
                user_chat_setting: {
                    background_color : formData.chat_color_toggle
                    }
            })
            contentType = 'application/json'   
        }
          else if (id === "wallpaper-btn") {
            const formData = new FormData();
            formData.append('wallpaper', image[0]); // Append the image file
    
            // Assign FormData object to data
            data = formData;
            // contentType = 'multipart/form-data'
        }
      APIContents.UpdateData(data, auth, contentType)
        .then((resp) => {
            console.log(resp)
            window.location.reload()
        })
    }
    
    // const [walletToggleChecked, setWalletToggleChecked] = useState(Boolean)

    const activationToggle = (e) => {
        if (e.target.id === 'wallet-toggle'){
            const _ = !userProfile.currentuser?.wallet[0]?.is_activated || false
            setFormData({
                ...formData,
                'wallet_is_activated' : _
            });
    
        }
        else if (e.target.id === "display_bio-toggle"){
            setFormData({
                ...formData,
                'display_bio' : !(userProfile.currentuser.display_bio)
            });
    
        }

        else if (e.target.id === "display_phone-toggle"){
            setFormData({
                ...formData,
                'display_phone' : !(userProfile.currentuser.market_info.display_phone)
            });
    
        }
        UpdateDataBtn(e)


    }

    const checkPasswordLength = (inputValue) => {
        const minLength = 6;
        const mediumLength = 10;


        if (inputValue.length < minLength){
            return 'weak';
        } else if (inputValue.length < mediumLength) {
            return 'medium';
        } else {
            return 'strong';
        }

    }

    const checkCompliance = (e) => {
        const inputValue = e.target.value
        const inputName = e.target.name



        if (inputName === 'username'){
        
            // const regexPattern = /^[a-zA-Z0-9_]+$/;
            const regexPattern = /^[a-zA-Z0-9_]*$/;
    
            
            // if (regexPattern.test(inputValue)){
            //     setUsername(inputValue)
    
            // } 
            // else {
            //     console.log('invalid input')
            // }
        } else if (inputName === 'email'){
            setFormData({
                ...formData,
                [inputName] : inputValue
            });
            const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (regexPattern.test(inputValue)){
                e.target.style.borderColor = 'green';
            }
            else {
                e.target.style.borderColor = 'red';
            }
        } 
        else if (inputName === 'phone'){
            setFormData({
                ...formData,
                [inputName] : inputValue
            });
            const regexPattern = /^(\+\d{1,3}\s?)?(\d{11})$/;
            // const regexPattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

            if (regexPattern.test(inputValue)){
                e.target.style.borderColor = 'green';
            }
            else {
                e.target.style.borderColor = 'red';
            }
        }
        else if (inputName === 'NewPassword') {
            setFormData({
                ...formData,
                ["verifyNewPassword"] : ""
            });
            const strength = checkPasswordLength(inputValue)

            switch (strength) {
                case 'weak':
                    e.target.style.borderColor = 'red';
                    break;
                case 'medium':
                    e.target.style.borderColor = 'orange';
                    break;
                case 'strong':
                    e.target.style.borderColor = 'green';
                    break;
                    default:
                    break;
            }

            setFormData({
                ...formData,
                [inputName] : inputValue
            });
 
        }
        else if (inputName === 'verifyNewPassword'){
            if (formData.NewPassword === inputValue){
                e.target.style.borderColor = 'green'
            } else {
                e.target.style.borderColor = 'red'

            }
            setFormData({
                ...formData,
                [inputName] : inputValue
            });
        }

    }



    return (
        <>
            <MainNavBar/>
            {/* <Settings /> */}


            <Routes>
                <Route index element={<Settings />} />
                
                <Route path="profile"  element={<ProfileSettings 
                userProfile={userProfile} auth={auth} 
                BASE_URL={BASE_URL} formData={formData} 
                setFormData={setFormData} checkCompliance={checkCompliance}
                UpdateDataBtn={UpdateDataBtn} setImage={setImage} 
                activationToggle={activationToggle} />} />

                <Route path="marketplace"  element={<MarketPlaceSettings 
                userProfile={userProfile} auth={auth} BASE_URL={BASE_URL}
                formData={formData} setFormData={setFormData} UpdateDataBtn={UpdateDataBtn}
                activationToggle={activationToggle}
                />} />
                
                <Route path="chat"  element={<ChatSettings
                userProfile={userProfile} auth={auth} BASE_URL={BASE_URL}
                formData={formData} setFormData={setFormData} UpdateDataBtn={UpdateDataBtn}
                activationToggle={activationToggle} setImage={setImage}
                />} />
                <Route path="contact"  element={<ContactSettings
                userProfile={userProfile} auth={auth} BASE_URL={BASE_URL}
                formData={formData} setFormData={setFormData} UpdateDataBtn={UpdateDataBtn}
                activationToggle={activationToggle}                
                />} />
                <Route path="about"  element={<AboutSettings />} />
                
            </Routes>

        </>
    )
}