import React, {useState, useEffect } from 'react';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Router,Routes, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import MarketPlace from './pages/marketplace/marketplace'
import Profile from './pages/profile/profile';
import Chat from './pages/chat/chat';
import Notification from './pages/notification/notification';
import Register from './pages/authentication/register/register';
import Login from './pages/authentication/login/login';
import Settings from './pages/settings/settings';
import ChatBody from './pages/chat/chat_item';
import APIContents from './api/api';
import { useCookies } from 'react-cookie';

import ReactGA from "react-ga";


import { CookiesProvider } from 'react-cookie';
import ProfileSettings from './components/settings/profile';
import MarketPlaceSettings from './components/settings/marketplace';
import ChatSettings from './components/settings/chat';
import AboutSettings from './components/settings/about';
import SettingsRouter from './pages/settings/settingsroute';
import NotFound from './pages/404/notfound';
import Verification from './pages/authentication/verify/verifyaccount';
import ResetPassword from './pages/authentication/resetpassword/resetpassword';
import SearchResultPage from './pages/search/searchuser';





const App = () => {
  let token = useCookies('loginToken')
  let auth =  token[0]['loginToken']


  const [posts, setPosts] = useState([])
  // const [users, setUsers] = useState([])

  // "homepage": "https://demo.nearbyvendor.com",


  // const BASE_URL = 'https://nearbyvendor.onrender.com'
  const BASE_URL = 'https://source.nearbyvendor.com'
  	
	let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']



  let userOnlineStatus = useCookies('onlineStatus')



  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const [userIpAddress, setUserIpAddress] = useState('');
  const [geoLocation, setGeoLocation] = useState({
    city: ''
  });
  const [filterDisplayStyle, setFilterDisplayStyle] = useState('none')


  // const TRACKING_ID = "UA-295989742-1";

  const GA_MEASUREMENT_ID = 'G-TBSYGZ8PM6';

  ReactGA.initialize(GA_MEASUREMENT_ID);

  //  // Use useLocation to get the current location
  //  const location = useLocation();
  //  // Use useHistory to get the history object
  //  const history = useHistory();
 
  //  useEffect(() => {
  //    // Track page view on every route change
  //    const unregister = history.listen((newLocation) => {
  //      ReactGA.set({ page: newLocation.pathname });
  //      ReactGA.pageview(newLocation.pathname);
  //    });
 
  //    return unregister;
  //  }, [history]);
 
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);
    // console.log(location.pathname)
  }, [location]);


  const [status, setStatus] = useCookies(['onlineStatus'])


  const stat = status['onlineStatus']

  //get user online status 


  const [userStatus, setUserStatus] = useState(stat ? stat : 'offline')

	useEffect(()=> {
		const userIsOnline = () => {
      if (userOnlineStatus[0]['onlineStatus']) {
        setUserStatus('online')
        setStatus('onlineStatus', 'online')

      }
		}
		const userIsOffline = () => {
      setStatus('onlineStatus', 'offline')
			setUserStatus('offline')
		}

		window.addEventListener('online', userIsOnline)
		window.addEventListener('offline', userIsOffline)

		return () => {
			window.addEventListener('online', userIsOnline)
			window.addEventListener('offline', userIsOffline)
	
		}

	}, [userStatus])


  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Track page view on every route change
  //   const unregister = navigate.listen((location) => {
  //     // ReactGA.pageview(location.pathname);
      
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname);
    
  //   });

  //   return unregister;
  // }, [navigate]);


  function shuffle(array) {

    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    // return array;
}



  // APIContents.Profiles(auth)
  // .then(response => setUsers(response))
  // .then(console.log(users))
  // .catch(error=> console.log(error))

  useEffect(()=> {
    if (posts){
      setLoading(true)
    }
  
  }, [])
  
  useEffect(()=> {
      APIContents.Feeds(auth)
      .then(resp => {
        setPosts(resp);
        setLoading(false)

      })
      .catch(error=> console.log(error))

  
    }, [refreshCounter])


  useEffect(() => {

    getUserIp();

  }, [refreshCounter])


  const getUserIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();
      setUserIpAddress(data)

    } catch (error) {
      console.log("failed to fetch IP: ", error)

    }
  }

  // const fetchIpInfo = async () => {
  //   try {
  //     const response = await fetch(`http://ip-api.com/json/${userIpAddress}`)
  //     // const response = await fetch(`https://ip-api.com/json/${userIpAddress}`)
  //     const data = await response.json();

  //     setGeoLocation(data);
  //     console.log(data)

  //   }catch (error) {
  //     console.log(error)
  //   }
  // }

  if (Object.keys(geoLocation).length === 0) {
    // fetchIpInfo()
    console.log(Object.keys(geoLocation).length)
  }

  const [reversedPost, setReversedPost] = useState('');

  


  useEffect(()=> {

    if (posts){
      const Interval = setInterval(()=> {
        shuffle(posts)
      }, 500)
      setReversedPost(posts.slice().reverse())
  
      return () => clearInterval(Interval)
    }
  }, [posts])

  const props = {
    "loading": loading,
    "reversedPost" : reversedPost,
    "posts" : posts,
    "refresh" : () => setRefreshCounter(prev => prev + 1),
    "userLocation" : geoLocation,
    "userCity" : geoLocation.city,
    
  }



  return ( 
    <CookiesProvider>
    {/* <Router basename={process.env.PUBLIC_URL}> */}
      <Routes>
        
      <Route path="/" element={<MarketPlace props = {props}  
      filterDisplayStyle = {filterDisplayStyle} setFilterDisplayStyle = {setFilterDisplayStyle}/>} />  
      {/* <Route path="/posts/:post" element={<MarketPlace  />} />   */}
        {/* <Route path="/api" element={<H />} />         */}
        <Route path="/profile/:username" element={<Profile props = {props} userStatus={userStatus} setUserStatus={setUserStatus}  />}  />
        <Route path="/chats" element={<Chat  />} />
        <Route path="/chats/:uniquecode" element={<ChatBody userStatus={userStatus} />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/activate" element={<Verification />} />
        <Route path="//resetpassword" element={<ResetPassword />} />
        <Route path="/register"  element={<Register />} />
        <Route path="/Login"  element={<Login props = {props} />} />

        <Route path="/search" element={<SearchResultPage /> } />
        <Route path="/settings/*" element={<SettingsRouter /> } />

          {/* <Route path="/settings" >
          <Route index element={<Settings />} />
          <Route path="profile"  element={<ProfileSettings />} />
          <Route path="marketplace"  element={<MarketPlaceSettings />} />
          <Route path="chat"  element={<ChatSettings />} />
          <Route path="about"  element={<AboutSettings />} />
          
          </Route>   */}

        {/* <Route path="/404" render={() => <Redirect to="/" />} /> */}

        <Route path='/*' element={<NotFound />} />
      </Routes>
    {/* </Router>       */}
    </CookiesProvider>
  );

}

export default App;
