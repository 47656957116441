// import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import MainNavBar from '../../components/navigation/second-nav';
import './settings.css';
import Login from '../authentication/login/login';
import ProfileSettings from '../../components/settings/profile';
import MarketPlaceSettings from '../../components/settings/marketplace';
import ChatSettings from '../../components/settings/chat';
import ContactSettings from '../../components/settings/contact';
import AboutSettings from '../../components/settings/about';
import { useEffect, useState } from 'react';
import APIContents from '../../api/api';
import { useCookies } from 'react-cookie';



const Settings = () => {


    const navigate = useNavigate();


    // const { username } = useParams()




    const [selectedTab, setSelectedTab] = useState('')

    const changeTab = (Tab) => {
        setSelectedTab(Tab)
        navigate(`/settings/${Tab}`)
        // const tab = document.getElementById('display');
        // const entire = document.getElementById('default');

        // const content = document.getElementById(Tab)
        // console.log(content)
        // tab.innerHTML = content.innerHTML
        // tab.style.display = 'flex'
        // entire.style.display = "none"
        
        console.log('updated')


        // if (Tab === 'profile'){
        //     return (<><ProfileSettings /></>)
        // } 
        // else {
        //     console.log(Tab)
        // }
    }


    // const handleGoBack = ()=> {
    //     const defaultTab = document.getElementById('default'); 
    //     changeTab(defaultTab);    
    // } 

    return(
        <>
        {/* <MainNavBar/> */}
        {
            true ?
            <>
    <div id="default" className="entire-body">
        <div id="head-content" className="head-content">
            <h4>Settings</h4>
        </div>
        <div id="profile-icon" onClick={() => changeTab('profile')} className="body-content-icon">
        <img style={{width: "20px", marginRight: "10px",}} src="/static/images/user.png" alt=""/>
        <h4>
            Profile</h4>
    </div>
    <div id="marketplace-icon" onClick={() => changeTab('marketplace')} className="body-content-icon">
        <img style={{height: "20px", width: "20px", marginRight: "10px",}} src="/static/images/shop.png" alt=""/>

        <h4>
                        MarketPlace</h4>
    </div>
    <div id="chat-icon" onClick={() => changeTab('chat')} className="body-content-icon">
        <img style={{height: "20px", width: "20px", marginRight: "10px",}} src="/static/images/message.png" alt=""/>

        <h4>
                        Chats & Notifications</h4>
    </div>
    <div id="contact-icon" onClick={() => changeTab('contact')} className="body-content-icon">
        <img style={{height: "20px", width: "20px", marginRight: "10px",}} src="/static/images/contactus.png" alt=""/>

        <h4>
                        Contact Us</h4>
    </div>
    <div id="about-icon" onClick={() => changeTab('about')} className="body-content-icon">
        <img style={{height: "20px", width: "20px", marginRight: "10px",}} src="/static/images/aboutus.png" alt=""/>

        <h4>
                        About Us</h4>
    </div>

    </div>
    <div className="sub">

        {/* <ProfileSettings userProfile={userProfile} BASE_URL={BASE_URL} auth={auth} formData={formData} setFormData={setFormData} />
        <MarketPlaceSettings userProfile={userProfile} BASE_URL={BASE_URL} auth={auth}  />
        <ChatSettings />
        <ContactSettings />
        <AboutSettings />
 */}

    

    </div>
            </> :
            <>
            {/* <Login/>
            {navigate('/login')} */}
            </>
        }
        {/* <Outlet /> */}
    </>    
    )
}

export default Settings;
