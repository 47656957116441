import React, { useState } from 'react';
import styled from 'styled-components';
import './wallet.css'

const WalletContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
`;


const TransactionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TransactionItem = styled.li`
  margin-bottom: 10px;
`;

const Wallet = ({ closeModal }) => {
  const [balance, setBalance] = useState(1000);
  const [amount, setAmount] = useState('');
  const [transactions, setTransactions] = useState([]);

  const handleDeposit = () => {
    const depositAmount = parseFloat(amount);
    if (depositAmount > 0) {
      setBalance(balance + depositAmount);
      setTransactions([
        ...transactions,
        { type: 'Deposit', amount: depositAmount, date: new Date().toLocaleString() },
      ]);
      setAmount('');
    }
  };

  const handleWithdraw = () => {
    const withdrawAmount = parseFloat(amount);
    if (withdrawAmount > 0 && withdrawAmount <= balance) {
      setBalance(balance - withdrawAmount);
      setTransactions([
        ...transactions,
        { type: 'Withdrawal', amount: withdrawAmount, date: new Date().toLocaleString() },
      ]);
      setAmount('');
    }
  };

  return (
    // <WalletContainer>
    //   <h2>Your Wallet</h2>
    //   <p>Balance: ${balance}</p>

    //   {/* Deposit and Withdraw Form */}
    //   <form>
    //     <label>
    //       Amount:
    //       <input
    //         type="number"
    //         value={amount}
    //         onChange={(e) => setAmount(e.target.value)}
    //       />
    //     </label>
    //     <button type="button" onClick={handleDeposit}>
    //       Deposit
    //     </button>
    //     <button type="button" onClick={handleWithdraw}>
    //       Withdraw
    //     </button>
    //   </form>

    //   {/* Transaction List */}
    //   <h3>Transaction History</h3>
    //   <TransactionList>
    //     {transactions.map((transaction, index) => (
    //       <TransactionItem key={index}>
    //         {transaction.type} - ${transaction.amount} ({transaction.date})
    //       </TransactionItem>
    //     ))}
    //   </TransactionList>

    //   <button onClick={closeModal}>Close</button>
    // </WalletContainer>
    <WalletContainer>
        <p style={{fontSize : "20px", fontWeight: "700"}}>Coming Soon!!!</p>
    {/* <button onClick={closeModal}>Close</button> */}
  </WalletContainer>
);
};

export default Wallet;
