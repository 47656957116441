import React, { useEffect, useState } from "react";
import APIContents from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router";
import './toggle.css'; // Import CSS for styling (customize as needed)



function ProfileSettings ({userProfile, BASE_URL, auth, formData, setFormData, checkCompliance, UpdateDataBtn, setImage, activationToggle}) {

    // const [DisplayBioToggleChecked, setDisplayBioToggleChecked] = useState(false)

    

    const navigate = useNavigate()

    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')
    // const [email, setEmail] = useState('')
    // const [dob, setDob] = useState()
    // const [phone, setPhone] = useState(0)
    // const [nationality, setNationality] = useState('')
    // const [city, setCity] = useState('')
    // const [address, setAddress] = useState('')
    // const [bio, setBio] = useState('')



    

    useEffect(()=> {
        if (userProfile.currentuser){
            const user = userProfile.currentuser;
            setFormData({
                ...formData,
                firstName: user.user.first_name,
                lastName: user.user.last_name,
                email: user.user.email,
                dob: user.dob,
                phone: user.phone,
                nationality: user.nationality,
                city: user.city,
                state: user.state,
                address: user.address,
                bio: user.bio


            });
        }
    }, [userProfile]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name] : value
        });

    };
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);



    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 

    return (
        userProfile.currentuser !== undefined && (
            <div className="entire-body">
            <div id="head-content" className="head-content">
                <span className="go-back">
                </span>
                <h4>
                    {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                    <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                    {/* </a> */}
                    Profile Settings</h4>
            </div> 
            <div className="profile-top">
            <h3>Cover Photo</h3>
                <div className="profile-cover-img">
                    <img  id="profile-cover" src={userProfile.currentuser.coverimg ? BASE_URL + '/media/' + userProfile.currentuser.coverimg : BASE_URL + '/media/defaultpic.png'} className="major-img" />
                    {/* <img id="profile-cover" src="{{user_profile.coverimg.url}}" alt="" className="major-img" /> */}
                    {/* <img  id="profile-cover" src="media/defaultpic.png" alt="" />
                    <img src="/static/images/uploadicon.png" alt=""  className="hover-img"/> */}
                </div>
                <form>
                    <label htmlFor="coverimg">Cover Photo:</label>
                    <input type="file" accept="image/*" name="coverimg" onChange={e => setImage(e.target.files)} id="cover-pic-upload"/> 
                    <button id="cover-pic-btn" type="submit" onClick={(e) => UpdateDataBtn(e)}>Update</button>
                </form>
            </div>

            <div className="profile-top">
                <h3>Profile Photo</h3>
                <div className="profile-picx">
                    <img  id="profile-pic" src={userProfile.currentuser.profileimg ? BASE_URL + '/media/' + userProfile.currentuser.profileimg : BASE_URL + '/media/defaultpic.png'} alt="test" className="major-img" />
                    {/* <img src={ BASE_URL +"/static/images/uploadicon.png"} alt=""  className="hover-img"/> */}
                </div>
                <form>
                    <label htmlFor="profileimg">Profile Photo:</label>
                    <input type="file" accept="image/*" onChange={e => setImage(e.target.files)} name="profileimg" id="profile-pic-upload"/>
                    <button id="profile-pic-btn" type="submit" onClick={(e) => UpdateDataBtn(e)}>Update</button>
                </form>
            </div>
        <div className="profile-top">
            <h3>Personal Info:</h3>
            <div className="personal-info">
                    <label htmlFor="firstName">First Name:</label>
                    <input type="text" value={formData.firstName} 
                    onChange={handleInputChange}
                    name="firstName" id="firstName"/>
                    <label htmlFor="lastName">Last Name:</label>
                    <input type="text" value={formData.lastName} 
                    onChange={handleInputChange}
                        // onChange={(e)=> setLastName(e.target.value)}
                    name="lastName" id="lastName"/>
                    <label htmlFor="email">Email Address:</label>
                    <input type="email" value={formData.email} 
                    onChange={(e) =>checkCompliance(e)}
                    // onChange={(e)=> setEmail(e.target.value)}
                    name="email" id="email"/>
                    <label htmlFor="dob" title="Date of Birth">D.O.B:</label>
                    <input type="date" value={formData.dob} 
                    onChange={handleInputChange}
                        // onChange={(e)=> setDob(e.target.value)}
                    name="dob" id="dob"/> <br></br>
                    <label htmlFor="phone">Phone Number:</label>
                    <input readOnly type="text" value={formData.phone} 
                    // onChange={(e) =>checkCompliance(e)}
                        // onChange={(e)=> setPhone(e.target.value)}
                    name="phone" id="phone"/>
                    <label htmlFor="nationality">Nationality:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="nationality" id="nationality" value={formData.nationality} onChange={handleInputChange}>
                        <option> -- select a country -- </option>
                        <option value="nigeria">Nigeria</option>
                        <option value="others"> Others</option>
                        </select>

                        <label htmlFor="state">State:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="state" id="state" value={formData.state} onChange={handleInputChange}>
                        <option> -- select a state -- </option>
                        <option value="FCT-Abuja">FCT-Abuja</option>
                        <option value="Lagos">Lagos</option>
                         </select>

                        <label htmlFor="city">City:</label>
                    <input type="text" value={formData.city} 
                    onChange={handleInputChange}
                        // onChange={(e)=> setCity(e.target.value)}
                    name="city" id="city"/>

                    <label htmlFor="address">Address:</label>
                    <input type="text" value={formData.address} 
                    onChange={handleInputChange}
                        // onChange={(e)=> setAddress(e.target.value)}
                    name="address" id="address"/>
                    <label htmlFor="bio">Bio:</label>
                    <input type="text" value={formData.bio} 
                    onChange={handleInputChange}
                        // onChange={(e)=> setBio(e.target.value)}
                    name="bio" id="bio"/>
                    <button type="submit" id="data-submission" onClick={(e) => UpdateDataBtn(e)}>Update</button>
            </div>
    
            </div>
    
            <div className="profile-top">
                <h3>Privacy & Security:</h3>
                <div className="personal-info">
                        {/* <label htmlFor="current-password">Forgot Password:</label>
                        <input  
                        value={formData.OldPassword}
                        onChange={(e)=> checkCompliance(e)}
                        type="text" 
                        placeholder="Current Password" 
                        name="current-password" 
                        id="current-password"/> */}

                        
                        <form>
                        <div style={{display: 'flex',
                            alignItems: 'center'
                            }} className="password-cover">
                        <input  
                        value={formData.NewPassword}
                        onChange={(e)=> checkCompliance(e)}
                        type={passwordVisible ? "text": "password"} 
                        placeholder="New Password" 
                        name="NewPassword" 
                        id="NewPassword"/>
                        <div
                        style={{
                            position: 'absolute',
                            right: '69px'
                        }}
                        className='eyeIconContainer'>
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            id="toggle-password-icon"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                        </div>

                        </div>

                        <input  
                        value={formData.verifyNewPassword}
                        onChange={(e)=> checkCompliance(e)}
                        type="password" 
                        placeholder="Confirm Password" 
                        name="verifyNewPassword" 
                        id="verifyNewPassword"/>

                        <button id="newpassword-submission" type="submit"onClick={(e) => UpdateDataBtn(e)}>Update</button>

                        </form>
                </div>
            </div>

            <div className="profile-top">
                <h3>General</h3>
                <div className="personal-info">

                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">
                    <label htmlFor="wallet-toggle" > Activate Wallet</label>
                {
                    userProfile.currentuser  ? <>
                    <input style={{width: "fit-content"}} name="wallet-toggle" id="wallet-toggle" type="checkbox" checked={userProfile.currentuser.wallet[0]?.is_activated} onChange={e => activationToggle(e)} />
                    </> : <></>
                }

                    </div>


                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">

                    <label htmlFor="display_bio-toggle" > Display Bio</label>
                     <input  style={{width: "fit-content"}} name="display_bio-toggle" id="display_bio-toggle" type="checkbox" checked={userProfile.currentuser.display_bio} onChange={e => activationToggle(e)} />
                    </div>
                    {/* <button className="verification">
                        ACTIVATE
                    </button> */}
                    {/* <span>Past Transactions</span> */}
                     <div className="transactions">
                     </div>
    
                      
                </div>
            </div>

        </div>
    
        )
    )
}

export default ProfileSettings;