import React, { useEffect, useRef, useState } from 'react';
import './filter.modules.css';

const Filters = ({filters, setFilters, currentCity, setUserCity,  filterDisplayStyle, setFilterDisplayStyle}) => {
    const formatedNumber = (number)=> {
        return number.toLocaleString()
    }

    // const [deliveryTime, setDeliveryTime] = useState([])

    const DeliveryTime = [
        'default',
        '30mins-1hr',
        '1hr-24hrs',
        '1day-3days',
        '3days-7days',
        'less<1month',
        '1month-3months',
        'more>3months',
    ]

    const State = [
        { label: '-- select a state --', value: '' },
        { label: 'FCT-Abuja', value: 'FCT-Abuja' },
        { label: 'Lagos', value: 'Lagos' },
        ]
 

    // const toggleDeliveryTime = (time) => {
    //     if (deliveryTime.includes(time)) {
    //         setDeliveryTime()
    //     }
    // }


    useEffect(() => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          city: currentCity,
        }));
      }, [currentCity]);




    const handleInputChange = (e)=> {
        const {name, value } =  e.target;
        // if (name === 'city') {
        //     setUserCity(value)            

        // }
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const [displayStyle, setDisplayStyle] = useState('none')

    useEffect(()=> {
        const updateDisplayStyle = () => {
            const filter_content = document.getElementById('filterContainer')

            setFilterDisplayStyle((window.innerWidth > 1000 || filter_content.style.zIndex == 10) ? 'flex' : 'none')
        }

        updateDisplayStyle()

        window.addEventListener('resize', updateDisplayStyle);

        return () => {
            window.removeEventListener('resize', updateDisplayStyle)
        }

    }, [window.innerWidth])

    const setDisplay = (newDisplayStyle) => {
        setFilterDisplayStyle(newDisplayStyle)
    }


    

    const mobileMenuRef = useRef(null);

    const RenderMobileMenu = window.innerWidth <= 1000;

    return (
        <div id='filter-component' style={{display: filterDisplayStyle}} className="preview-profile" 
        // ref={mobileMenuRef} aria-hidden
        >
<h3 className="filter-header">FILTER BY</h3>
<div className="each-filter">
<label htmlFor="category">category:</label>
<input className="post-filter" value={filters.category} onChange={handleInputChange} name="category" placeholder="select a category" type="text"/>
</div>
<div className="each-filter">
<label htmlFor="">price:</label>
<label htmlFor="min">min</label>
<input className="post-filter" id="min" value={filters.min} onChange={handleInputChange} name="min" placeholder="min" type="number"/>
<label htmlFor="max">max</label>
<input className="post-filter" id="max" value={filters.max} onChange={handleInputChange} name="max" placeholder="max" type="number"/>
</div>
<div className="each-filter">
<label htmlFor="State">State:</label>
{/* <input className="post-filter" value={filters.state} onChange={handleInputChange} name="state" placeholder="choose a state" type="text"/> */}
<select className="post-filter" name='state'
value={filters.State}

onChange={handleInputChange}
>
    {
        State.map((item, index) => (
            <option key={index} className="post-filter" value={item.value}>
                {item.label}
            </option>
        ))
    }


</select> 
</div>
<div className="each-filter">
<label htmlFor="city">city:</label>
<input className="post-filter" value={filters.city} onChange={handleInputChange} name="city" placeholder="city" type="text"/>
</div>
<div className="each-filter">

<label htmlFor="delivery-time">Delivery time:</label>


{/* 
{
    DeliveryTime.map(item => (
        <div key={item} style={{display: 'flex', flexDirection: 'column'}}>
        <label >
            {item}
            <input 
            type='checkbox'
            checked={deliveryTime.includes(item)}/>
        </label>
        </div>
    ))
} */}
 <select className="post-filter" name='delivery'
value={Filters.delivery}

onChange={handleInputChange}
>
    {
        DeliveryTime.map((item, index) => (
            <option key={index} className="post-filter">
                {item}
            </option>
        ))
    }


</select> 
{/* <input className="post-filter" value={Filters.delivery} onChange={handleInputChange} name="delivery" placeholder="preferred delivery time" type="text"/> */}
</div>
{/* <button className="filter" type="submit">FILTER</button> */}
</div>
    )
}

export default Filters;