import React, { useEffect, useState } from 'react';
import MainNavBar from '../../components/navigation/second-nav';
import './chat.css';
import ClipLoader from "react-spinners/ClipLoader";
import APIContents from '../../api/api';
import { useCookies } from 'react-cookie';
import moment from 'moment';



const Chat = () => {

  const formatTime = (timestamp) =>{
    // moment.utc(item.created).local().startOf('seconds').fromNow()

    const now = moment()
    const diffSec = now.diff(timestamp, 'seconds');

    if (diffSec < 60) {
      return 'just now';

    } else {
      return moment(timestamp).fromNow()
    }
  }
  const [chatRoom, setChatRoom] = useState([]); // Replace with your chat_room data
  const [loading, setLoading] = useState(false)
  const [searchChat, setSearchChat] = useState('')
  // const user = {}; // Replace with your user data

  const BASE_URL = 'https://source.nearbyvendor.com'
  // const BASE_URL = 'http://192.168.8.117:8000'

	let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']


  const filteredChats = (chatRoom || []).filter(chat => {
    if (chat.user1.username === loggedInUser) {
      if (chat.user2.username.includes(searchChat)){
        return true
      }
    } else {
      if (chat.user2.username.includes(searchChat)){
        return true
      }
    }
    return false
    
  })

  console.log(filteredChats
    )

  

  	let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']

  useEffect(()=> {
    setLoading(true)
    APIContents.ChatLists(auth)
    .then(resp => {
      if (resp.response !== 'no chat room') {
        setChatRoom(resp)
      }
      setLoading(false)

    })
  }, [])


  const [user, setUser] = useState('')


  useEffect(()=> {
    if (chatRoom){
      setUser((chatRoom[0]?.user1.username === loggedInUser)? chatRoom[0]?.user1  :  chatRoom[0]?.user2)

    }
  
  }, [user, chatRoom, loggedInUser])

  

  const count = chatRoom.filter(item => item.is_read === false && item.sender !== loggedInUser).length;
  return (
    <>
      <MainNavBar />
      {
            user && 
            <div className="chat-body">
            <div className="chat-body-c">
            <div style={{backgroundColor : user?.profile?.background_color !== null ? user?.profile?.background_color : ''}}  className="chat-body-outer">
                <div className="chat-search">
                  <form action="/search">
                    <input type="text" name="search" onChange={(e)=>setSearchChat(e.target.value)} value={searchChat} id="search" placeholder="Search..." />
                    {/* <button type="submit">Search</button> */}
                  </form>
                </div>
              </div>
    
              {
                loading ? 
                <>
                <div style={{display: 'flex',
                    width: 'inherit',
                    height: 'inherit',
                    textAlign: 'center',
                    padding: '0% 0',
                    alignItems: 'center',
                    justifyContent: 'center'}} >
    
                    <ClipLoader
                    // color={color}
                    loading={loading}
                    // cssOverride={override}
                    size={150}
                    // style={{margin: "auto"}}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </div>
              </> 
                : 
                <>
              <div className="chat-container">
                <h3>Messages</h3>
    {             count && count === 1 ?
                <p>You have {count} unread message</p>
                :
                <p>You have {count} unread messages</p>
    }            <div className="main-row">
                  {chatRoom && chatRoom.length > 0 ? (
                    <div className="chat-name-list">
                      {filteredChats.map(item => (
                        <a key={item.uniquecode} href={`/chats/${item.uniquecode}`}>
                          <div style={{backgroundColor: (item.is_read || item.sender === loggedInUser) ? "white" : "#f2f2f2"}} className="sideBar-body">
                            <div className="sideBar-img">
                              <img src={`${BASE_URL}${item.user1.username === loggedInUser ? item.user2.profile.profileimg : item.user1.profile.profileimg}`} alt="" />
                            </div>
                            <div className="sideBar-main">
                              <div style={{display: "flex", flexDirection : "column"}} className="user-message">
                              <span className="name-icon">{item.user1.username === loggedInUser ? item.user2.username : item.user1.username}</span>
                              <span  >{item.message}</span>
                              </div>
                              <div className="sideBar-time">
                                <span className="">{
                                  formatTime(item.created)
                }</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                ) : (
                  <h5 id="nomessage">You don't have any messages yet.</h5>
                )}
                </div>
              </div>
                
                </>
              }
        
            </div>
          </div>
    
      }
    </>
  );
};

export default Chat;
