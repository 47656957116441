import React from "react";
import APIContents from "../../api/api";
import { useCookies } from "react-cookie";


const CreateComment = ({MainloggedInUser, isAuth, post, commentResponse, BASE_URL}) => {
    
    let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']

    // const [motion, setMotion] = useState('');


    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']

    return (
        <div className="post-comment">
        {
            loggedInUser !== undefined && loggedInUser ? 
            <>
        <img src={MainloggedInUser ? `${BASE_URL}${MainloggedInUser.profile.profileimg}` : ''} 
        className="profile-img-edit" alt='profile-img'/>

            </>
            :
        <img src="/static/images/defaultpic.png" 
        className="profile-img-edit" alt='profile-img'/>

        }


        <input type="text" 
        placeholder={
            post.user.vendor?.allow_comments ? "post a comment ..." : "comment is disabled."
        }
         
        autoFocus={false}
        onKeyUp={
            (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    // if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    const inputValue = e.target.value;
                    commentResponse(loggedInUser, post.id, inputValue)//`comment-input-${post.id}`)
                    e.target.value='';
                } else if (e.key === 'Enter' && e.shiftKey) {
                    const inputValue = e.target.value;
                    e.target.value = inputValue + '\n'; // or use '\r\n' for Windows line breaks
                  }

            }
        } 
        id={`comment-input-${post.id}`}
        readOnly={(isAuth && post.user.vendor?.allow_comments) ? false : true} 
        className="main-comment" name="main-comment" 
        />

        <div className="post-comment-icons">
            <a href="/"> <i className="uil-image"></i></a>
            <a href="/"> <i className="uil-video"></i></a>
        </div>
        <button type="submit" hidden>post</button>

    </div>

    )
}

export default CreateComment;