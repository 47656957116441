import React from 'react';
import styles from "./outer-nav.module.css";
import { Link, useLocation } from 'react-router-dom';

const OuterNav = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';

    return (
        <div className={styles.nav}>
            <Link to="/">
                <img className={styles.logo} src={process.env.PUBLIC_URL + 'static/images/logo.png'} alt="logo" />
            </Link> 

            <div className={styles.capitalize}>
                {isLoginPage ? (
                    <Link to="/register" className={styles.navlink}>
                        Sign Up
                    </Link>
                ) : (
                    <Link to="/login" className={styles.navlink}>
                        Login
                    </Link>
                )}
            </div>
        </div>
    )
}

export default OuterNav;
