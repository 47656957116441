import React, { useEffect, useState } from 'react';
import OuterFooter from "../../../components/footer/outer-footer";
import OuterNav from "../../../components/navigation/outer-nav";
import styles from "./login.module.css";
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import APIContents from '../../../api/api';
import ClipLoader from 'react-spinners/ClipLoader';

import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// import { HandleLogin } from '../../../api/api';
// import { json } from 'react-router-dom';

// const BASE_URL = 'http://192.168.8.117:8000/api'
const BASE_URL = 'https://source.nearbyvendor.com/api/renderapi'
// const BASE_URL = 'https://nearbyvendor.onrender.com/api/renderapi'


const Login = (props) => {

    useEffect(() => {
        // Track page view
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);



    document.title = "Login";

    const navigate = useNavigate();

    const [token, setToken] = useCookies(['loginToken'])
    const [tokenUser, setTokenUser] = useCookies(['username'])
    const [status, setStatus] = useCookies(['onlineStatus'])



    const [passwordVisible, setPasswordVisible] = useState(false)
    const [display, setDisplay] = useState(false)
    const [response, setResponse] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)

    function notify(){
        setDisplay(true)
        setTimeout(()=> {
            setDisplay(false)
        },
        4000

        )
    }

    function handleLoginClick(e)   {
        e.target.hidden = true
        setLoading(true)

        const loginData = {
            username: username.toLowerCase(),
            password: password,
            device: `${navigator.platform} - ${navigator.userAgent}` // Get device information
        };
        APIContents.Login(loginData)
        .then((resp) => {
            setResponse(resp);
            if (resp['message'] === 'invalid user' || token['loginToken'] === null){
                notify()
                setLoading(false)
                e.target.hidden = false
            }
            else{
                
                setToken('loginToken', resp['token'])
                setTokenUser('username', resp['user'])
                setStatus('onlineStatus', 'online')
                navigate('/')

            };

        })
        .catch(error => console.log(error))
    }

    // useEffect(()=> {
    //     setLoading(true)

    // }, [])


    // const handleLoginClick = async () => {
    //     const userData = await HandleLogin({username, password})
    //     console.log(userData)
    // }


    return ( 
        <div className={styles.outerContainer}>
            {/* <OuterNav isLoggedIn={false} /> User is logged in */}
            <OuterNav/>
            {/* <div className="container"> */}
                <div className={styles.formContainer}>
                    <div className={styles.content}>
                    <h1 className={styles.formHead}> Sign In </h1>
                    
                    <div>
                        <h5 style={{display: display ? 'block' : 'none'}} className={styles.error}>Invalid Credentials!</h5>
                    </div>

                    <form className={styles.form}>

                    <div className={styles.part}>
                    <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username or email address"/>
                    {/* <p>Email must be a valid address, e.g email@marketconnect.com</p> */}
                    <div className={styles.passwordContainer}>
                    <div className={styles.passwordInputContainer}>
                        <input style={{width: "96%", paddingRight: "30px"}} type={passwordVisible ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="password" />
                        </div>
                        <div className={styles.eyeIconContainer}>
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            id="toggle-password-icon"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                        </div>
                    </div>


                    {/* <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password"/> */}
                    {/* <p>Password must be alphanumeric (@, _ and - are allowed) and must be 8 - 20 characters</p> */}
                    <p></p>

                    <p style={{textAlign: "right"}}>
                    <a href={`/resetpassword`}>Forgot Password? </a>
                    </p>

                    <button type="button" onClick={(e) => handleLoginClick(e)} className={styles.button}>Login
                    </button>
                    {
                        loading ? 
                        <button type="button" className={styles.button}>
                        <ClipLoader
                        // color={color}
                        loading={loading}
                        // cssOverride={override}
                        size={15}
                        // style={{margin: "auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                        </button>
    
                    //     <div style={{width: "inherit", display: "flex", height: "inherit", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                    //   </div>

                      : 
                      <></>
                    }

                    </div>
{/* 
                    {state.map(item => 
                        <h2 key = {item.id}>{item.title} - {item.body} - {item.userId} </h2>
                        )} */}

                </form>


                    </div>
                </div>

            <OuterFooter/>
        </div>
    
    )
    
}
export default Login;