import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function ContactSettings ({userProfile, BASE_URL, auth, formData, setFormData, UpdateDataBtn, activationToggle}) {


            
    const navigate = useNavigate()
        
    // const handleGoBack = ()=> {
    //     const defaultTab = document.getElementById('default'); 
    //     defaultTab.style.display = 'flex'
    //     console.log('updated now ')
    //     // changeTab(defaultTab);    
    // } 
    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 

    useEffect(()=> {
        if (userProfile.currentuser){
            const user = userProfile.currentuser;
            setFormData({
                ...formData,
                username: user?.user?.username,
                phone: user?.user?.phone,
                subject: user?.user?.username,
                feedback_description: user?.user?.username,
                ticket_number: user?.user?.username,
                ticket_subject: user?.user?.username,

               

            });
        }
    }, [userProfile]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name] : value
        });

    };

    const sendFeedback = (e) => {

    }

    const submitTicket = (e) => {

    }
    

    return (
        
                // userProfile && userProfile.currentuser !== undefined &&

                <div className="entire-body">
                <div id="head-content" className="head-content">
                    <span className="go-back">
                    </span>
                    <h4>
                            {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                            <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                            {/* </a> */}
                        Reach out to us</h4>
                </div>
                {
                    userProfile.currentuser !== undefined && userProfile.currentuser !== null ? <>
                <div className="profile-top">
                    <h3>Drop your Feedback</h3>
                    <div className="personal-info">
                        {/* <form action="/settings" method="post"> */}
                            <label htmlFor="username">Username:</label>
                            <input readOnly type="text" name="username" value={formData.username} id=""/>
        
                            <label htmlFor="phone">Phone Number:</label>
                            <input readOnly type="text" value={formData.phone} onChange={handleInputChange}
                            name="phone" id="phone"/>
                            <label htmlFor="subject">Subject:</label>
                            <input type="text" name="subject" onChange={handleInputChange} value={formData.subject} id=""/>

                            <label htmlFor="feedback_description">Description:</label>
                            <textarea style={{
                                width: '100%',
                                margin: '10px 0',
                                padding: '8px 5px',
                                boxSizing: 'border-box',
                                border: '2px solid #e5dddd',
                                minHeight : '200px'
                               }} value={formData.feedback_description} onChange={handleInputChange} name="feedback_description" />        
                            {/* <label htmlFor="delivery_time">O</label>
        
                            <p style={{fontSize: "10px"}}>Note: please upload in pdf or zip format your registration documents to get verified easily. e.g cac documents etc.</p>
                            <input readOnly type="file" accept="pdf, zip" name="other_documents" id="other_documents"/> */}
                            <button type="submit" onClick={e => sendFeedback(e)}>submit</button>
                        {/* </form> */}
                    </div>
                    </div>
                    <div className="profile-top">
                        <h3>Join our community</h3>
                        <div 
                        style={{display: 'flex',
                        justifyContent: 'space-evenly'}}
                        
                        className="personal-info">
                        <a 
                        style={{textDecoration: 'none', color: 'inherit'}}
                        href="/">whatsapp</a>
                        <a 
                        style={{textDecoration: 'none', color: 'inherit'}}
                        href="/">x</a>
                        <a 
                        style={{textDecoration: 'none', color: 'inherit'}}
                        href="/">telegram</a>
                        <a 
                        style={{textDecoration: 'none', color: 'inherit'}}
                        href="/">twitter</a>
                        </div>
                    </div>
                    <div className="profile-top">
                        <div
                        style={{
                            display: 'flex',
                            width: '95%',
                            height: '35px',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                        >
                        <h3>Support Ticket</h3> 
                                <button style={{maxWidth: "100px"}} className="verification">
                                create
                            </button>


                        </div>
                        <div className="personal-info">
                            <form action="">
                                <label htmlFor="ticket_number">Ticket Number</label>
                                <input type="text" name="ticket_number" id="ticket_number" value={formData.ticket_number} onChange={handleInputChange} />
                                <label htmlFor="ticket_subject">Subject</label>
                                <input type="text" name="ticket_subject" id="ticket_subject" value={formData.ticket_subject} onChange={handleInputChange}/>

                                <label htmlFor="support_description">Description:</label>
                                <textarea style={{
                                width: '100%',
                                margin: '10px 0',
                                padding: '8px 5px',
                                boxSizing: 'border-box',
                                border: '2px solid #e5dddd',
                                minHeight : '200px'
                               }} value={formData.ticket_description} onChange={handleInputChange} name="support_description" />        


                                <button type="submit" id="bank-details-update" onClick={e => submitTicket(e)} >submit</button>
                            </form>
                        </div>
                    </div>                
                    
                    </> : <></>
                }
            
            
        
                    
        
            
            </div>               
                    )
              
        
    

        {/* <div id="">
        <h4 style={{textAlign: "center", margin: "50% 0"}}>This page is Under Construction!!!
            <a href="" onClick={() => changeTab('default')}>
                <button style={{border: "none", marginTop: "20px"}}>
                    <img style={{width: "20px", height: "15px"}} src="/static/images/go-back.svg" alt=""/>
                    GO BACK</button>
            </a>
    
        </h4>
    </div> */}

}

export default ContactSettings;