import React, { useState, useEffect } from "react";
import './popup.css';
import './feeds.modules.css';
import PopUp from './createpost_popup';
import { useCookies } from 'react-cookie';



// import PopUp from "./createpost_popup";



function CreatePost(props) {
    // const BASE_URL = 'http://192.168.8.115:8000'
    // const BASE_URL = 'http://192.168.8.117:8000'
    const BASE_URL = 'https://source.nearbyvendor.com'
    // const BASE_URL = 'https://nearbyvendor.onrender.com'

    console.log(props)


    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']
    const [isAuth, setIsAuth] = useState(false)

    useEffect(()=> {
        if (auth !== undefined && auth !== null){
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        }
    }, [])


    const showForm = () => {

        if (props.loggedInUser.vendor !== null && props.loggedInUser.vendor !== undefined){
            document.getElementById("myForm").style.display = "flex";
        }
        else {

        }

        
    }



    return (
        <div className="create-post" style={{display: isAuth === true ? 'flex' : 'none'}}>
            <PopUp props={props}/>
                          <div className="top">
                                  <div className="profile-img-container">  

                                  {
                                        props.loggedInUser !== undefined && props.loggedInUser  ? 
                                        <>
                                        <a>
                                        {/* <a href={`/profile/${props.props.loggedInUser.username}`}> */}
                                        <img src={props.loggedInUser  !== undefined ? `${BASE_URL}${props.loggedInUser.profile.profileimg}` : ''}  
                                         alt='profile' className="profile-img" />
                                    </a>    

                                        </>
                                        :
                                        <a href="{}">
                                        <img src="/static/images/defaultpic.png" alt='profile' className="profile-img" />
                                    </a>    
  
                                    }


                                  </div>
                              <div className="create-content">
                                  <input type="text" onClick={showForm} readOnly placeholder="create a post ..." className="bg-transparent max-h-10 shadow-none" />
                                  <a href="{}"> <i className="uil-image"></i></a>
                                                                          
                              </div>    
                          </div>
            
        </div>
      )

}

export default CreatePost;