import React from 'react';
import styles from './outer-footer.module.css';
import { Link } from 'react-router-dom';


const OuterFooter = () => {
    return (
        <div className={styles.footer}>
        <div className={styles.footerContent}>
            <div className={styles.footerLink}>
                <Link to="#" className={styles.link}> About</Link>
                <Link to="#" className={styles.link}> Help</Link>
                <Link to="#" className={styles.link}> Terms</Link>
                <Link to="#" className={styles.link}> Privacy</Link>
            </div>
            <p className={styles.copyright}> © copyright 2023 by R.P.C.T Academy</p>
        </div>
    </div>
    )
}

export default OuterFooter;