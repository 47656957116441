import React, { useEffect, useState } from 'react';
import './popup.css';
import APIContents from '../../api/api';
import { useCookies } from 'react-cookie';
import Cropper from 'cropperjs';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';


function PopUp({props}){

    // const categories = ''

    // if (categories === 'goods') {}


 
    const [posted, setPosted] = useState(true)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [caption, setCaption] = useState('')
    const [category, setCategory] = useState('')
    const [price, setPrice] = useState('')
    const [images, setImages] = useState([])
    // const [eachImages, setEachImages] = useState([])
    const [choseCategory, setChoseCategory] = useState('')
    const [delivery, setDelivery] = useState('')


    const [cropped_img, setCropped_img] = useState([])
    const [croppers, setCroppers] = useState([]);
    const [loading, setLoading] = useState(false)




    const imageRef = []

    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']
    const [isAuth, setIsAuth] = useState(false)
  
    let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']

    useEffect(()=> {
        if (auth !== undefined && auth !== null){
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        } 
    }, [])


    const imgUpload = (e)=> {
        const files = Array.from(e.target.files)
        // const imageFiles = Array.from(files)

        if (!files || files === undefined || files.length === 0){
            // console.log(images)
            return null
    
        } else{
            // console.log(`${e.target.files} no`)
        
        // const DisplayImage = document.getElementById('edited-img')

        // files.forEach((file, index) => {
        //     const url =URL.createObjectURL(file);
    
        //     const imgElement = document.createElement('img')
        //     imgElement.src = url;
        //     imgElement.id = `image-edited-${index + 1}`;
        //     imgElement.className = 'image-uploaded';
    
        //     const imageWrapper = document.createElement('div');
        //     imageWrapper.appendChild(imgElement);
        //     imageWrapper.appendChild(document.createElement('br'));
    
        //     console.log(DisplayImage)

        //     DisplayImage.appendChild(imgElement)
        //     DisplayImage.appendChild(document.createElement('br'));

        //     var cropper = new Cropper(imgElement, {
        //     aspectRatio: 500 / 600,
        //     viewMode: 0,
        //     crop(event) {
        //     const croppedData = cropper.getCroppedCanvas().toDataURL();
        //     console.log(`${croppedData} cropped data`)
        //     console.log(`${cropper} cropper`)
        //     setCropped_img((prevImages) => [...prevImages, croppedData]);


                //   console.log(event.detail.x);
            //   console.log(event.detail.y);
            //   console.log(event.detail.width);
            //   console.log(event.detail.height);
            //   console.log(event.detail.rotate);
            //   console.log(event.detail.scaleX);
            //   console.log(event.detail.scaleY);


      
        //     },
        // });
        // setCropped_img(cropper)
            
                

    
    
        // })
        // console.log(cropped_img)
        setImages(files)


        }
    
    }

    const navigate = useNavigate();


    // useEffect(()=> {
    //     // navigate('/settings')
    //     props.props.props.props["refresh"]()
    //     setPosted(false)
    // }, [posted])


    // const DisplayImage = document.getElementById('edited-img')
    // console.log(DisplayImage)


    function renderImages(){

    if (!images || images === undefined || images.length === 0){
        // console.log(images)
        return null

    } else{
        // console.log(`${images['post']} yes`)

        // const files = Array.from(images);

        // images.map((image, index)=> {
        //     const url = URL.createObjectURL(image)

        //     return(
        //         <div key={`image-wrapper-${index}`}>
        //         <img src={url} 
        //         alt={`Uploaded Image ${index + 1}`} 
        //         className="image-uploaded" 
        //         width="220px" 
        //         ref={el => (imageRef[index] = el)}
        //         />
        //         <br />
        //     </div>                
        //     )
        // })




        // files.forEach((file, index) => {
        //     const url =URL.createObjectURL(file);
    
        //     const imgElement = document.createElement('img')
        //     imgElement.src = url;
        //     imgElement.id = `image-edited-${index + 1}`;
        //     imgElement.className = 'image-uploaded';
        //     imgElement.width = '220px';
    
        //     const imageWrapper = document.createElement('div');
        //     imageWrapper.appendChild(imgElement);
        //     imageWrapper.appendChild(document.createElement('br'));
    
        //     DisplayImage.appendChild(imageWrapper)
            

        //     var cropper = new Cropper(imgElement, {
        //     aspectRatio: 500 / 600,
        //     viewMode: 0,
        //     crop(event) {
        //       console.log(event.detail.x);
        //       console.log(event.detail.y);
        //       console.log(event.detail.width);
        //       console.log(event.detail.height);
        //       console.log(event.detail.rotate);
        //       console.log(event.detail.scaleX);
        //       console.log(event.detail.scaleY);
      
        //     },
        // });
        // setCropped_img(cropper)
            
                

    
    
        // })
    
    }
}


    // useEffect(()=> {
    //     imageRef.forEach((imgElement, index)=> {
    //         if (imgElement) {
    //             var cropper = new Cropper(imgElement, {
    //             aspectRatio: 500 / 600,
    //             viewMode: 0,
    //             crop(event) {
    //               console.log(event.detail.x);
    //               console.log(event.detail.y);
    //               console.log(event.detail.width);
    //               console.log(event.detail.height);
    //               console.log(event.detail.rotate);
    //               console.log(event.detail.scaleX);
    //               console.log(event.detail.scaleY);
      
    //             },
    //         });
    //         setCropped_img(cropped_img => [cropped_img, cropper])
    //         console.log(cropped_img)
    //         }
    //     })
    // }, [images])





     




    const CreatePost = (e) => {
        e.target.hidden = true
        setLoading(true)
        const formData = new FormData();

        const converted = JSON.stringify({
            title : title,
            description : description,
            caption : caption,
            category : category,
            price : price,
            delivery: delivery,
            user: loggedInUser,            
        })


        const imgArr = []
        formData.append('post', converted)
        images.forEach((image, index) => {
            formData.append(`images`, image )
        }); 
        // formData.append(`image`, imgArr )
        // console.log()
        // console.log(formData)

        // const Create = {
        //     image : images.files[0],
        //     post : {
        //         title : title,
        //         description : description,
        //         caption : caption,
        //         category : category,
        //         price : price,
        //         delivery: delivery,
        //         user: loggedInUser,
    
        //     }

        //     // cropped_img : cropped_img
        // }

        APIContents.CreateFeeds(formData, auth)
        .then((resp) => {
            // console.log(resp)
            window.location.reload();
            setLoading(false);

        })
        .catch(error => console.log(error))
    }



    const HideForm = () => {
        document.getElementById("myForm").style.display = "none";
    }
    return(
        <div className="form-popup" id="myForm">
        <div className="static-container">

        <form className="form-container" id="image-form">
            <label>Create post</label>
            <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Product Title" required />
            <select name="category" value={choseCategory} onChange={(e) => setChoseCategory(e.target.value)} id="category" required>
                {/* <div className="opt"> */}
                <option  value="">-- Category --</option>
                <option id="services_menu" value="services">Services</option>
                <option id="goods_menu" value="goods">Products</option>
                {/* </div> */}
            </select>
            {
                choseCategory === 'goods' ? 
                <>
                <select name="category" value={category} onChange={(e) => setCategory(e.target.value)} id="goods">
                {/* <div className="opt"> */}

                <option value=""></option>
                <option value="cloths">Electronics  (e.g., smartphones, laptops, cameras)</option>
                <option value="Fashion"> Fashion and Apparel (e.g., clothing, shoes, accessories) </option>
                <option value="Appliances"> Home and Kitchen (e.g., furniture, appliances, cookware) </option>
                <option value="Beauty"> Beauty and Personal Care (e.g., skincare, haircare, cosmetics) </option>
                <option value="Health"> Health and Wellness  (e.g., vitamins, supplements, fitness equipment) </option>
                <option value="Books"> Books and Media  (e.g., books, eBooks, CDs, DVDs) </option>
                <option value="Sports"> Sports and Outdoors  (e.g., sports equipment, camping gear, fitness apparel) </option>
                <option value="Toys"> Toys and Games  (e.g., educational toys, board games, puzzles) </option>
                <option value="Auto"> Automotive and Tools  (e.g., car accessories, tools, maintenance products) </option>
                <option value="Jewelry"> Jewelry and Watches  (e.g., necklaces, bracelets, luxury watches) </option>
                <option value="Baby"> Baby and Kids  (e.g., clothing, toys, nursery essentials) </option>
                <option value="Groceries"> Groceries and Pantry  (e.g., food items, beverages, snacks) </option>
                <option value="Pets"> Pets and Pet Supplies  (e.g., pet food, toys, grooming, products) </option>
                <option value="interior"> Home Decor and Furnishings  (e.g., wall art, rugs, curtains) </option>
                <option value="Stationaries"> Office and School Supplies  (e.g., stationery, desk accessories, school textbooks) </option>
                {/* </div> */}
            </select>

                </>
                : choseCategory === 'services' ?
                <>
            <select name="category" value={category} onChange={(e) => setCategory(e.target.value)} id="services">
                {/* <div className="opt"> */}
                <option value=""></option>
                <option value="Web"> Web Development and Design (e.g., website creation, UI/UX design) </option>
                <option value="Digital"> Digital Marketing (e.g., SEO, social media marketing, content creation) </option>
                <option value="Graphic"> Graphic Design  (e.g., logo design, branding, illustrations)</option>
                <option value="Photography"> Photography and Videography (e.g., event photography, product videography)</option>
                <option value="Writing"> Writing and  Editing Services (e.g., content writing, copyediting, proofreading) </option>
                <option value="Professional"> Professional Consulting  (e.g., business consulting, financial advisory) </option>
                <option value="Fitness"> Fitness Training  and Coaching (e.g., personal training, nutrition coaching) </option>
                <option value="Cleaner"> Home Cleaning  and Maintenance (e.g., house cleaning, handyman services) </option>
                <option value="Event"> Event Planning  and Management (e.g., wedding planning, corporate events) </option>
                <option value="Legal"> Legal Services (e.g., legal consultation, contract drafting, intellectual property) </option>
                <option value="painting"> Painting services </option>        
                {/* </div> */}
            </select>
                </> :
                <>
                </>
            }

            <input type="text" name="caption" value={caption} onChange={(e) => setCaption(e.target.value)} id="caption" placeholder="Caption"/>
            <input type="number" name="price" value={price} onChange={(e) => setPrice(e.target.value)} id="price" placeholder="Price"/>
            <div className="edited-img" id="edited-img">
                {renderImages()}
            </div>
            <input type="file" accept="image/*"  onChange={imgUpload}  id="image" multiple={props.loggedInUser.vendor?.allow_multi_image ? true : false} required/>
            {/* <input type="file" accept="image/*" name="image" id="image_edited_" multiple/> */}
            <select name="delivery" value={delivery} onChange={(e) => setDelivery(e.target.value)} id="services">
            <option value=''>-- Delivery Time -- </option>
            <option value='30mins-1hr'>30mins - 1hr</option>
            <option value='1hr-24hrs'>1hr-24hrs</option>
            <option value='1day-3days'>1day-3days</option>
            <option value='3days-7days'>3days-7days</option>
            <option value='less<1month'>less than 1 month</option>
            <option value='1month-3months'>1 month - 3 months</option>
            <option value='more>3months'>more than 3 months</option>
            </select>
            <input type="text" name="description"  value={description} onChange={(e) => setDescription(e.target.value)} id="description" placeholder="Description" required/>
            <div className="form-button">
                {
                    
                        loading ? 
                        // <div style={{width: "inherit", display: "flex", height: "inherit", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                        <ClipLoader
                        id="upload-btn"
                        // color={color}
                        loading={loading}
                        // cssOverride={override}
                        // size={150}
                        // style={{margin: "auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    //   </div>

                      : 
                      <>
                        <button type='button' onClick={(e)=> CreatePost(e)} id="upload-btn" className="btn">
                        UPLOAD
                        </button>
                        <button type="button" onClick={HideForm} className="btn cancel">CANCEL</button>    

                      </>
                    }

            </div>
        </form>
    </div>
</div>
)

}

export default PopUp;