import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import OuterFooter from "../../../components/footer/outer-footer";
import OuterNav from "../../../components/navigation/outer-nav";
import styles from './register.module.css';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';


import ReactGA from 'react-ga';

// const BASE_URL = 'http://192.168.8.117:8000/api/renderapi'
const BASE_URL = 'https://source.nearbyvendor.com/api/renderapi'
// const BASE_URL = 'https://nearbyvendor.onrender.com/api/renderapi'


const Register = () => {

    useEffect(() => {
        // Track page view
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    
    
    document.title = "Register"
    
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false)
    const [response, setResponse] = useState()
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [TCPolicy, setTCPolicy] = useState(false)
    const [username, setUsername] = useState('')
    const [Country, setCountry] = useState('')
    const [State, setState] = useState('')
    const [City, setCity] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [formIsFIlled, setformIsFIlled] = useState(false)
    const [loading, setLoading] = useState(false)



    const checkPasswordLength = (inputValue) => {
        const minLength = 6;
        const mediumLength = 10;


        if (inputValue.length < minLength){
            return 'weak';
        } else if (inputValue.length < mediumLength) {
            return 'medium';
        } else {
            return 'strong';
        }

    }

    const checkCompliance = (e) => {
        const inputValue = e.target.value
        const inputName = e.target.name



        if (inputName === 'username'){
        
            // const regexPattern = /^[a-zA-Z0-9_]+$/;
            const regexPattern = /^[a-zA-Z0-9_]*$/;
    
            
            if (regexPattern.test(inputValue)){
                setUsername(inputValue)
    
            } 
            // else {
            //     console.log('invalid input')
            // }
        } else if (inputName === 'email'){
            setEmail(inputValue)
            const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (regexPattern.test(inputValue)){
                e.target.style.borderColor = 'green';
            }
            else {
                e.target.style.borderColor = 'red';
            }
        } 
        else if (inputName === 'phone'){
            setPhone(inputValue)
            const regexPattern = /^(\+\d{1,3}\s?)?(\d{11})$/;
            // const regexPattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

            if (regexPattern.test(inputValue)){
                e.target.style.borderColor = 'green';
            }
            else {
                e.target.style.borderColor = 'red';
            }
        }
        else if (inputName === 'password') {
            setPassword2('')  
            const strength = checkPasswordLength(inputValue)

            switch (strength) {
                case 'weak':
                    e.target.style.borderColor = 'red';
                    break;
                case 'medium':
                    e.target.style.borderColor = 'orange';
                    break;
                case 'strong':
                    e.target.style.borderColor = 'green';
                    break;
                    default:
                    break;
            }

            setPassword(inputValue)
 
        }
        else if (inputName === 'password2'){
            if (password === inputValue){
                e.target.style.borderColor = 'green'
            } else {
                e.target.style.borderColor = 'red'

            }
            setPassword2(inputValue)
        }

    }

    // const patterns = {
    //     phone:/^\+[0-9]{1,3}\d{10}$|^\d{11}$/,
    //     username:/^[a-z\d]{5,15}$/i,
    //     password:/^[\w@-]{8,20}$/i,
    //     email:/^([\w\.-]+)@([a-z0-9-]+)\.([a-z]{2,4})(\.[a-z]{2,4})?$/,
    // };

    // function validate(field, regex) {
    //     const isValid = regex.test(field.value);
    //         field.className = isValid ?'valid' :'invalid';
    //     }

    // const handleInputChange = (e) => {
    //     validate(e.target, patterns[e.target.attributes.name.value]);
    // }

    // const handleTogglePassword = ()=> {
    //     setPasswordVisible((passwordVisible) => !passwordVisible)
    // }
    
    function notify(){
        setDisplay(true)
        setTimeout(()=> {
            setDisplay(false)
        },
        4000

        )
    
    }

    function handleRegistration(e){
        e.preventDefault();
        const Details = {
            username: username.toLowerCase(),
            email:email,
            first_name: firstName,
            last_name: lastName,
            password: password,
            market_info :{
                country: Country,
                state: State,
                city: City,
                phone: phone,    
            }
        }
        e.target.hidden = true
        setLoading(true)

        fetch(`${BASE_URL}/register`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token}`
            },
            body: JSON.stringify(Details),

        })
        .then(resp => resp.json())
        .then((resp) => {
            setResponse(resp);
            if (resp['message'] !== 'user registration was successful'){
                notify()
                setLoading(false)
                e.target.hidden = false
            } else{
                navigate(`/activate?username=${username.toLowerCase()}`)
                // navigate.push('/login')
            }
        })
        .catch(error=> console.log(error))
    }


    // if (password === password2 && username !== "" && storeName !== "")
    // {
    //     setformIsFIlled(true)
    // }


    return ( 
        
        <div className={styles.outerContainer}>
            {/* <OuterNav isLoggedIn={true} /> User is logged in */}
            <OuterNav/>
            {/* <div className="container"> */}
                <div className={styles.formContainer}>
                    <div className={styles.content}>
                    <h1 className={styles.formHead}> Sign Up <br/>
                    {/* <hr/> Merchant Account */}
                    </h1>
                    
                    <div>
                    <h5 style={{display: display ? 'block' : 'none'}} className={styles.error}>User already exists!</h5>
                    </div>

                    <form className={styles.form}>
                    <div className={styles.part}>
                        {/* <label htmlFor="" className={styles.label}>Store info: <span>*</span> <hr></hr></label> */}
                        <label className={styles.label} htmlFor="">Personal info:                        <span>*</span> <hr></hr>                       </label>
                        <p></p><p></p>
                        {/* <input type="text" value={storeName} onChange={(e) => setStoreName(e.target.value)} name="marketname" id="marketname" placeholder="Business Name"/> */}
                        <input type="text" value={username} onChange={(e) => checkCompliance(e)} name="username" placeholder="Username" autoComplete="off"/>
                        <p>Username must be alphanumeric and contain 5-15 characters</p>
                        <select value={Country} onChange={(e) => setCountry(e.target.value)}>
                        <option> -- select a country -- </option>
                        <option value="nigeria">Nigeria</option>
                        <option value="others"> Others</option>
                        </select>
                        <select value={State} onChange={(e) => setState(e.target.value)}>
                        <option> -- select a state -- </option>
                        <option value="FCT-Abuja">FCT - Abuja</option>
                        <option value="Lagos">Lagos</option>
                        {/* <option>Port Harcourt</option>
                        <option>Kogi</option>
                        <option>Niger</option> */}
                        </select>
                        <input type="text" value={City} onChange={(e) => setCity(e.target.value)} placeholder="city" />
                        <p></p><p></p></div>
                    <div className={styles.part}>
                    <input type="text" value={firstName}  onChange={(e) => setFirstName(e.target.value)} name="first_name" placeholder="First Name" />
                    <input type="text" value={lastName}  onChange={(e) => setLastName(e.target.value)} name="last_name" placeholder="Last Name"/>
                    <p></p><p></p></div>
                    
                    <div className={styles.part}>
                    <label className={styles.label} htmlFor="">Account info:                        <span>*</span> <hr></hr>                       </label>
                    <input type="email" value={email} onChange={(e) => checkCompliance(e)} name="email" placeholder="Email Address"/>
                    <p>Email must be a valid address, e.g email@marketconnect.com</p>
                    <input type="text" value={phone} onChange={(e) => checkCompliance(e)} name="phone" id="phone" placeholder="Phone Number"autoComplete='false'/>
                    <p>e.g +2349091404351 or 09091404351</p>
                    <div className={styles.passwordContainer}>
                    <div className={styles.passwordInputContainer}>
                        <input style={{width: "96%", paddingRight: "30px"}} type={passwordVisible ? 'text' : 'password'} value={password} onChange={(e) => checkCompliance(e)} name="password" placeholder="Password" />
                        </div>
                        <div className={styles.eyeIconContainer}>
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            id="toggle-password-icon"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                        </div>
                    </div>
                    <div className={styles.passwordContainer}>
                    <div className={styles.passwordInputContainer}>
                        <input style={{width: "96%", paddingRight: "30px"}} type={passwordVisible2 ? 'text' : 'password'} value={password2} onChange={(e) => checkCompliance(e)} name="password2" placeholder="Confirm Password" />
                        </div>
                        <div className={styles.eyeIconContainer}>
                        <FontAwesomeIcon
                            icon={passwordVisible2 ? faEyeSlash : faEye}
                            id="toggle-password-icon"
                            onClick={() => setPasswordVisible2(!passwordVisible2)}
                        />
                        </div>
                    </div>
                        <p>Password must be alphanumeric (@, _ and - are allowed) and must be 8 - 20 characters</p>

                    <p></p>

                    
                    <div style={{display: 'flex', flexDirection: "row"}} className='t&c'>
                        <input style={{width: 'fit-content'}} type='checkbox' checked={TCPolicy} onChange={e => setTCPolicy(!TCPolicy)} />
                        <span>I aggree with all the <a href='/'>terms & conditions</a></span>
                    </div>

                    <button type="submit" hidden={password2 !== "" && password === password2 && username !== "" && TCPolicy !== false ? false : true } onClick={(e)=> handleRegistration(e)} className={styles.button}>Sign Up</button>
                    {
                        loading ? 
                        <button type="button" className={styles.button}>
                        <ClipLoader
                        // color={color}
                        loading={loading}
                        // cssOverride={override}
                        size={15}
                        // style={{margin: "auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                        </button>
    
                    //     <div style={{width: "inherit", display: "flex", height: "inherit", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                    //   </div>

                      : 
                      <></>
                    }

                    </div>

                    {/* <div className={styles.test}>
                        <div className={styles.test}>
                            <input type="checkbox" name="merchant" id="chekcbox1" checked/>
                            <label htmlFor="chekcbox1"><span className={styles.test}></span> I Agree</label>
                        </div>
                        {/* <a href="#"> Terms and Conditions</a>
                    </div> */}
                    {/* <div className={styles.test}>
                        <p className={styles.test}> Do you have an account? </p>
                            {/* <a className={styles.test} href="/signin"> Login</a>
                    </div> */}
                </form>


                    </div>
                </div>

            {/* </div> */}
            <OuterFooter/>
        </div>
    )
}
export default Register;