import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from './comment.module.css';
import CreateComment from "./createcomment";
import styled from 'styled-components';

const CommentContainer = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommentAuthor = styled.span`
  font-weight: bold;
`;

const CommentText = styled.p`
margin-top: 5px;
margin-bottom: 5px;
font-size: 14px;
`;

const ReplyButton = styled.button`
  border: 1px solid #ccc;
  background-color: transparent;
  color: #555;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
`;

const ReplyInput = styled.input`
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 10px 5px 3px 0;
  font-size: 14px;
  border-radius: 5px;
  width: calc(100% - 85px);
`;
 

const CommentFeed = ({comment, index, isAuth, post, loggedInUser, commentResponse, commentCounter}) => {
    const [showReplyInput, setShowReplyInput] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [currentPost, setCurrentPost] = useState('')

    // console.log(replyText)

    // console.log(commentCounter)

    const handleReplyClick = (e) => {
        // e.target.style.display = 'none'
        setCurrentPost(e)
        setShowReplyInput(!showReplyInput);

        if (replyText !== ''){
            commentResponse(loggedInUser, post.id, replyText, comment.id)
            setReplyText('')
            setShowReplyInput(false);

        } else {

        }

    };

    return (
        <CommentContainer>
        <CommentHeader>
          <CommentAuthor>
            {comment.user === loggedInUser ? 'self' : comment.user}
          </CommentAuthor>
          <ReplyButton hidden={showReplyInput} onClick={(e) => handleReplyClick(comment.comment)}>
            Replyss
          </ReplyButton>
        </CommentHeader>
        <CommentText>
          {comment.comment}
        </CommentText>

        {isAuth && currentPost === comment.comment && showReplyInput && (
          <div>
            <ReplyInput
              type="text"
              readOnly={(isAuth && post.user.vendor?.allow_comments) ? false : true} 
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              placeholder={
                post.user.vendor?.allow_comments ? "reply comment" : "comment is disabled."
            }
    
              
            />
            <ReplyButton onClick={(e) => handleReplyClick()}>
              Reply
            </ReplyButton>
          </div>
        )}

  
        {/* Nested Comments */}
        {commentCounter
          .filter(coment => coment.post === post.id && coment.parent === comment.id)
          .map((commment, index) => (
            <>
            <CommentContainer key={index}>
              <CommentHeader>
                <CommentAuthor>
                  {commment.user === loggedInUser ? 'self' : commment.user}
                </CommentAuthor>
                <ReplyButton  hidden={showReplyInput} onClick={(e) => handleReplyClick(commment.comment)}>
                  Reply
                </ReplyButton>

              </CommentHeader>
              <CommentText>
                {commment.comment}
              </CommentText>
              {isAuth && currentPost === commment.comment && showReplyInput && (
                  <div>
                  <ReplyInput
                    type="text"
                    readOnly={(isAuth && post.user.vendor?.allow_comments) ? false : true} 
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder={
                      post.user.vendor?.allow_comments ? "reply comment" : "comment is disabled."
                  }          
                />
                  <ReplyButton onClick={(e) => handleReplyClick()}>
                    Reply
                  </ReplyButton>
                </div>
              )}

            </CommentContainer>
            </>
          ))
        }
  
        {/* Reply Input */}
      </CommentContainer>
    )

}

export default CommentFeed;