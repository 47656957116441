import React from "react";
import { useNavigate } from "react-router";

function AboutSettings () {
    const navigate = useNavigate()

    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 


    return (
        <>
                        <div className="entire-body">
                <div id="head-content" className="head-content">
                    <span className="go-back">
                    </span>
                    <h4>
                            {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                            <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                            {/* </a> */}
                        About Us</h4>
                </div>
                <>
                <div className="profile-top">
                    <h3>Privacy Policy</h3>
                    <div>
                        <h1>Privacy Policy</h1>
                        <p>Welcome to Nearby Vendor, where we prioritize your privacy and security. We've prepared this Privacy Policy to outline how we handle your information and to protect both you and our platform.</p>

                        <h2>1. Information We Collect</h2>
                        <p>We gather various types of data to ensure the functionality and security of Nearby Vendor:</p>
                        <ul>
                            <li>Personal Information: Including your name, email address, phone number, address, and payment details for verification and communication purposes.</li>
                            <li>Additional Verification Data: Such as BVN (Bank Verification Number), NIN (National Identification Number), or similar identifiers, for enhanced security measures.</li>
                            <li>Sensitive Data: This may include IP addresses, geolocation information, and cookies for security and operational purposes.</li>
                        </ul>

                        <h2>2. Purpose of Information Collection</h2>
                        <p>We collect and process your data for specific purposes, including:</p>
                        <ul>
                            <li>Account Creation and Management: To establish and maintain your Nearby Vendor account.</li>
                            <li>Service Provision: To provide you with our e-commerce platform services efficiently and effectively.</li>
                            <li>Security Measures: To safeguard the integrity and security of our platform against unauthorized access, fraud, and other potential risks.</li>
                        </ul>

                        <h2>3. How We Collect Information</h2>
                        <p>We obtain information through various channels:</p>
                        <ul>
                            <li>Directly from You: When you provide information during registration, transactions, or interactions with Nearby Vendor.</li>
                            <li>Through Automated Technologies: Such as cookies, tracking pixels, and similar tools utilized for authentication, analytics, and user experience enhancement.</li>
                            <li>From Third Parties: In some cases, we may receive data from third-party services for verification or security purposes.</li>
                        </ul>

                        <h2>4. Use of Information</h2>
                        <p>We utilize your data primarily for:</p>
                        <ul>
                            <li>Service Delivery: To fulfill transactions, provide customer support, and enhance your experience on Nearby Vendor.</li>
                            <li>Legal Compliance: To adhere to applicable laws, regulations, and legal processes, including requests from law enforcement or government authorities.</li>
                            <li>Platform Optimization: To analyze usage patterns, troubleshoot technical issues, and improve our services.</li>
                        </ul>

                        <h2>5. Data Sharing</h2>
                        <p>Your information may be shared in limited circumstances:</p>
                        <ul>
                            <li>With Authorized Third Parties: Such as service providers, payment processors, or government authorities when required by law or for security reasons.</li>
                            <li>As Permitted by You: With your consent or as otherwise outlined in this Privacy Policy.</li>
                        </ul>

                        <h2>6. Data Security Measures</h2>
                        <p>We employ robust security measures to safeguard your data:</p>
                        <ul>
                            <li>Encryption: Utilizing industry-standard encryption protocols to protect data transmission and storage.</li>
                            <li>Access Controls: Restricting access to your information to authorized personnel only.</li>
                            <li>Regular Audits: Conducting periodic security assessments and audits to identify and mitigate potential vulnerabilities.</li>
                        </ul>

                        <h2>7. Your Rights and Responsibilities</h2>
                        <p>You have certain rights regarding your data:</p>
                        <ul>
                            <li>Access and Correction: You may request access to and correction of your personal information held by Nearby Vendor.</li>
                            <li>Deletion: You may request the deletion of your data under certain circumstances, subject to legal and operational requirements.</li>
                            <li>Responsibility: You are respon  nch
                                 sible for maintaining the confidentiality of your account credentials and for any activities conducted under your account.</li>
                        </ul>

                        <h2>8. International Data Transfers</h2>
                        <p>Your information may be transferred and processed outside your country of residence:</p>
                        <ul>
                            <li>By using Nearby Vendor, you consent to the transfer of your data to jurisdictions with differing data protection laws.</li>
                            <li>We ensure that such transfers comply with applicable legal requirements and provide adequate safeguards for your data.</li>
                        </ul>

                        <h2>9. Data Retention</h2>
                        <p>We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy:</p>
                        <ul>
                            <li>We may retain certain information even after you deactivate your account or cease using Nearby Vendor, as required by law or for legitimate business purposes.</li>
                            <li>However, we will not retain your data longer than is necessary or permitted by law.</li>
                        </ul>

                        <h2>10. Compliance with Laws</h2>
                        <p>We strive to comply with applicable privacy laws and regulations:</p>
                        <ul>
                            <li>This Privacy Policy is designed to align with the requirements of relevant legislation, including but not limited to GDPR, CCPA, and local data protection laws.</li>
                            <li>If any provision of this policy conflicts with applicable law, the relevant legal requirements shall prevail.</li>
                        </ul>

                        <h2>11. Updates and Amendments</h2>
                        <p>We may update this Privacy Policy from time to time:</p>
                        <ul>
                            <li>We will notify you of any material changes to this policy through prominent notices on our platform or via email.</li>
                            <li>By continuing to use Nearby Vendor after such changes, you consent to the revised Privacy Policy.</li>
                        </ul>

                        <h2>12. Contact Us</h2>
                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your data:</p>
                        <ul>
                            <li>Please contact us at <a href="mailto:info@nearbyvendor.com">info@nearbyvendor.com</a>.</li>
                            <li>You can also fill out the feedback form on the Contact Us page for assistance.</li>
                        </ul>
                    </div>

                    </div>
                    <div className="profile-top">
                        <h3>Terms and Condition</h3>

                        <div>
                            <h1>Terms and Conditions</h1>
                            <p>Welcome to Nearby Vendor! These Terms and Conditions govern your use of our social media platform for e-commerce businesses. By accessing or using Nearby Vendor, you agree to abide by these terms. Please read them carefully.</p>

                            <h2>1. Acceptance of Terms</h2>
                            <p>By accessing or using Nearby Vendor, you agree to be bound by these Terms and Conditions, our Privacy Policy, and any additional guidelines or rules posted on the platform. If you do not agree to these terms, please do not use Nearby Vendor.</p>

                            <h2>2. Account Registration</h2>
                            <p>To access certain features of Nearby Vendor, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                            <h2>3. User Conduct</h2>
                            <p>You agree to use Nearby Vendor for lawful purposes only and to comply with all applicable laws and regulations. You may not engage in any activity that disrupts, damages, or interferes with the functioning of Nearby Vendor or its users.</p>

                            <h2>4. Intellectual Property</h2>
                            <p>The content, trademarks, and intellectual property on Nearby Vendor are owned by or licensed to us. You may not use, reproduce, or distribute any content from Nearby Vendor without our prior written consent.</p>

                            <h2>5. User-Generated Content</h2>
                            <p>By posting, uploading, or submitting content to Nearby Vendor, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, and distribute your content for the purposes of operating and promoting Nearby Vendor. You are solely responsible for the content you post on Nearby Vendor and must ensure that it does not infringe upon the rights of any third party.</p>

                            <h2>6. Limitation of Liability</h2>
                            <p>Nearby Vendor is provided on an "as-is" and "as-available" basis. We make no warranties or representations regarding the reliability, accuracy, or completeness of the platform. We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of Nearby Vendor.</p>

                            <h2>7. Indemnification</h2>
                            <p>You agree to indemnify and hold Nearby Vendor and its affiliates, officers, directors, employees, and agents harmless from any claims, losses, liabilities, damages, costs, or expenses arising out of your use of Nearby Vendor or your violation of these Terms and Conditions.</p>

                            <h2>8. Termination</h2>
                            <p>We reserve the right to suspend or terminate your access to Nearby Vendor at any time and for any reason, without prior notice or liability.</p>

                            <h2>9. Modifications to Terms</h2>
                            <p>We may modify these Terms and Conditions at any time. The updated terms will be effective upon posting on Nearby Vendor. Your continued use of Nearby Vendor after any changes constitutes your acceptance of the modified terms.</p>

                            <h2>10. Governing Law</h2>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of US and Nigeria, without regard to its conflict of law principles.</p>

                            <h2>11. Contact Us</h2>
                            <p>If you have any questions, concerns, or feedback regarding these Terms and Conditions, please contact us at <a href="mailto:info@nearbyvendor.com">info@nearbyvendor.com</a>.</p>
                        </div>

                        <div 
                        style={{display: 'flex',
                        justifyContent: 'space-evenly'}}
                        
                        className="personal-info"></div>
                    </div>
                    
                    </>
            </div>                

        </>
    //     <div id="about">
    //     <h4 style={{textAlign: "center", margin: "50% 0"}}>This page is Under Construction!!!
    //             {/* <a href="" onClick={handleGoBack}> */}
    //                 <button style={{border: "none", marginTop: "20px"}}>
    //                     <img style={{width: "20px", height: "15px"}} src="/static/images/go-back.svg" alt=""/>
    //                     GO BACK</button>
    //             {/* </a> */}
        
    //         </h4>
    //    </div>
    
    )
}

export default AboutSettings;