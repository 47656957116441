import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from "react-router-dom";
import styles from "./second-nav.module.css";
import Filters from '../feeds/filter';
// import{ Filters as Filtered } from "../feeds/filter";
// import MarketPlace from "../../pages/marketplace/marketplace";
// import images from "../public/static";






const MainNavBar = (props) => {

    const navigate = useNavigate();

    const [usedtoken, setUsedToken, reverseUsedToken] = useCookies(['loginToken'])
    const [tokenUser, setTokenUser, reverseUserToken] = useCookies(['username'])

    const handleLogout = () => {
        reverseUsedToken('loginToken')
        reverseUserToken('username')

        navigate('/')
    }


	let loggedInUserToken  = useCookies('username')
	let username = loggedInUserToken[0]['username']

    
    // const [motion, setMotion] = useState('');
    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']
    const [isAuth, setIsAuth] = useState(false)
    
    useEffect(()=> {
        if (auth !== undefined && auth !== null){
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        } 
    }, [])

    const [isWidthLarger, setIsWidthLarger] = useState(window.innerWidth > 1000);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    
    const RenderMobileMenu = window.innerWidth <= 1000;


    // const DisplayFilter = ()=> {
    //     const main_content = document.getElementById('marketplace')
    //     const filter_content = document.getElementById('filter-component')
    //     // filter_content.style.display = 'flex'
    //     // filter_content.style.width = '99%'
    //     // filter_content.style.margin = '4px 0'
    //     // filter_content.style.border = '2px solid'
    //     // filter_content.style.position = 'relative'
        
    //     main_content.style.flexDirection = 'column'
    //     // main_content.style.width = "79%"

    //     console.log(filter_content)


    // }

    const toggleFilter =()=> {

        // console.log(mobileMenuRef.current)

        // const filter_content = document.getElementById('filter-component')
        const filter_content = document.getElementById('filterContainer')

    
        if (filter_content !== null){
            if (filter_content.style.display === 'flex' ){
                if (window.innerWidth <= 1000) {
                filter_content.style.display = 'none'       
                } else {
                    filter_content.style.display = filter_content.style.display       
    
                }
                
            } else {
                filter_content.style.display = 'flex'
            } 
    
        }
        // DisplayFilter()
        // console.log(filter_content.style.display)
    }

      // Step 1: Create a state variable to manage pop-up visibility

//   console.log(RenderMobileMenu)


useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        console.log('handle event listener')
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
        toggleFilter()
        console.log('add event listener')
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
        console.log('remove event listener')
        toggleFilter()
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [isMobileMenuOpen]);

  // Step 3: Create a function to toggle the mobile menu
  const toggleMobileMenu = () => {
    // if (!isMobileMenuOpen){
        // toggleFilter()
        setIsMobileMenuOpen(!isMobileMenuOpen);
        // setIsFiltersVisible(!isFiltersVisible); // Toggle Filters visibility

    // } else {
    //     setIsMobileMenuOpen(true);

    // }
  };


//   const [filters, setFilters] = useState({
//     category: '', // Initialize with a default value or an empty string
//     min: '',
//     max: '',
//     state: '',
//     city: '',
//     delivery: 'default', // Initialize with a default value
//   });
    
    return (
        <>
        <div className={styles.mainNavBar}>
            <div className={styles.leftSide}>
                    <Link to='/' className={styles.link}>
                        <img className={styles.logo} src='/static/images/logo.png' alt="logo" />
                     </Link>
            </div>
            <div className={styles.mobileMenu}>

            {/* <img style={{padding: "5px"}} src='/static/images/wallet.png' alt=""/>

                <h4>N 00.00</h4> */}
            </div>


            <div className={styles.rightSide}>
                        
                        <div className={styles.menuLogoItem}>
                            <Link to="/">
                                <img src='/static/images/marketplace.jpg' alt=""/>
                            </Link>
                            <Link className={styles.menuText} to="/">Marketplace</Link>
                    </div>
                        <div className={styles.menuLogoItem}>
                            <Link to={isAuth ? `/profile/${username}`: '/login' }>
                                <img className={styles.profileImgEdit} src='/static/images/user-icon.png' alt=""/>
                            </Link>
                            <Link className={styles.menuText} to={isAuth ? `/profile/${username}`: '/login' }>Profile</Link>
                    </div>
                        <div className={styles.menuLogoItem}><Link to={isAuth ? `/chats`: '/login'}>
                            <img src='/static/images/chat.png' alt=""/>
                            </Link><Link className={styles.menuText} to={isAuth ? `/chats`: '/login'}>Chats</Link>
                    </div>
                        <div className={styles.menuLogoItem}>
                            <Link to={isAuth ? `/notification`: '/login'}>
                            <img src='/static/images/notification.png' alt=""/>
                            </Link> <Link className={styles.menuText} to={isAuth ? `/notification`: '/login'}>Notifications</Link>
                    </div>
                        <div className={styles.menuLogoItem}>
                            <Link to={isAuth ? `/settings`: '/login'}>
                                <img src='/static/images/settings.png' alt=""/>
                            </Link>
                            <Link className={styles.menuText} to={isAuth ? `/settings`: '/login'}>Settings</Link>
                    </div>


                    </div>
                
                {
                    isAuth ? (            
                    <div className={styles["logout-container"]}>
                    <div className={styles["icon"]}>
                    <img className={styles.logo} style={{width: "30px", padding: "6px 0px 6px 40px"}} src='/static/images/logout2.png' alt="logo" />
                    </div>
                    <div onClick={()=> handleLogout()} className={styles["text"]}>Logout</div>
                    </div>) : (

                    <div className={styles.capitalize}>

                        <Link to="/login" className={styles.navlink}>
                            Login
                        </Link>
                    </div>
                    
                    )

                }


            {/* <div className="main-nav-bar-links">
                <Link to="/">Marketplace</Link>
                <Link to="/profile">Profile</Link>
                <Link to="/chat">Chat</Link>
                <Link to="/notification">Notification</Link>
                <Link to="/settings">Settings</Link>
            </div> */}
        </div>
        {/* {isMobileMenuOpen && window.innerWidth <= 1000 && (
        <div 
        className={styles.mobileMenuPopUp} 
        // aria-hidden={window.innerWidth <= 1000}
        style={{
        display: RenderMobileMenu ? 'flex' : 'none',
        width: '90vw',
        position: 'fixed',
        background: 'white',
        border: '2px solid',
        zIndex: '10'}} 
        ref={mobileMenuRef}>
          {/* Add your mobile menu content here
          {  <Filters filters={filters} setFilters={setFilters} />}  
          <div className={styles.mobileMenuItem}>Item 1</div>
          <div className={styles.mobileMenuItem}>Item 2</div> 
          You can add more menu items or components as needed 
        </div>
      )} */}
        </>
    )

}
export default MainNavBar;
