import React, { useEffect, useRef, useState } from 'react';
import CreatePost from './createpost';
// import './feeds.modules.css';
import { useCookies } from 'react-cookie';
import APIContents from '../../api/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import ClipLoader from "react-spinners/ClipLoader";



import styles from './comment.module.css';
import CommentFeed from './commentFeeds';
import CreateComment from './createcomment';
import SinglePostPopup from '../singlepost/singlePost';

const PostContents =({props, followers, follow, MainloggedInUser, filters}) => {


    const location = useLocation();

    // const searchParams = location.searchParam('postId')
    const searchParam = new URLSearchParams(location.search) 

    const __ = searchParam.get('postId')
    const [singlePostId, setSinglePostId] = useState(__) 
    const [singlePostContent, setSinglePostContent] = useState({})
    const [singlePostPopUp, setSinglePostPopUp] = useState(false)


    // const BASE_URL = 'http://192.168.8.117:8000'
	// const BASE_URL = 'http://192.168.68.118:8000'
    // const BASE_URL = 'https://nearbyvendor.onrender.com'
    const BASE_URL = 'https://source.nearbyvendor.com'

    // const BASE_URL = 'http://192.168.8.115:8000'
    // const BASE_URL = 'http://127.0.0.1:8000'

    const [selectedPostIndex, setSelectedPostIndex] = useState(0);
    // const [loading, setLoading] = useState(false);

    const handlePostChange = (index) => {
      setSelectedPostIndex(index);
    };

    // const timer = setInterval(()=> {
    // }, 5000)

    // const [timed, setTimed] = useState(timer)

    const value = {
        data: props['reversedPost'],
        refresh: props['refresh'],
        filters: filters,
        followers: followers,
        loading: props['loading']

    }



    // console.log(JSON.stringify(followers[0]))

    const isFollowing =(postusername)=> {
        return followers.some(follower => follower.user.username === postusername)
    } 


    let loggedInUserToken  = useCookies('username')
	const [loggedInUser, setloggedInUser] = useState('')
    

    useEffect(()=> {
        if (loggedInUserToken !== undefined) {
            setloggedInUser(loggedInUserToken[0]['username'])
        }
        else {
            setloggedInUser('anonymous')
        }
    }, [loggedInUserToken])
    isFollowing()

    // const [motion, setMotion] = useState('');
    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']

    const [isAuth, setIsAuth] = useState(false)
    const [likesChecker, setLikesChecker] = useState()
    const [liked, setLiked] = useState(false)
    const [commented, setCommented] = useState([])

    const [commentCounter, setCommentCounter] = useState([])
    // const [replyText, setReplyText] = useState('');

    const [visibleComments, setVisibleComments] = useState({});

    // const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef(null);
    const imgRef = useRef(null);



  const loadMoreComments = ( postId) => {


    const id = document.getElementById(`${postId}`).id
    // const currentComment = commentCounter.some(comm => comm.post === postId)
    const currentVisibleComments = visibleComments[postId] || 0;
    
    if (postId === id) {
        setVisibleComments(prevVisibleComments => ({
        ...prevVisibleComments,
        [postId]: currentVisibleComments + 2,}));
    }
  };
  
//   console.log(visibleComments)


    const navigate = useNavigate()
  
    useEffect(()=> {
        if (auth !== undefined && auth !== null){
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        } 
    }, [])

    // function to comment on posts
    const commentResponse = (loggedInUser, postId, comment, parent)=> {
        if (!parent){
            parent = null
        }

        let comments = ''
        if (typeof comment !== 'string'){
            const coments = document.getElementById(comment)
            comments = coments.value 
            setCommented(coments.value)  
            coments.value = ''
            // con(coments.value)   
        } else {
            // setReplyText(comment)
            comments = comment
        }

        if (loggedInUser !== undefined && loggedInUser !== null){
            const comment = {
                user: loggedInUser,
                post: postId,
                comment: comments,
                parent: parent
            }
            APIContents.Comment(comment, auth)
            .then((resp) =>{ setCommentCounter(prev => [...prev, resp])
            })
            // navigate(`/comment/${postId}`)
        } else {
            loggedInUser = 'XXXX'
            // navigate(`/comment/${postId}`)
        }
    
    }



    const [showMoreComments, setShowMoreComments] = useState({})
    // const [showMoreCommentsCounter, setShowMoreCommentsCounter] = useState(3)

    // function to handle increase in comments display 

    const handleShowMoreComments = (currpost)=> {
        setShowMoreComments((prev) => {
            const prevCounter = prev.hasOwnProperty(currpost.id) ? prev[currpost.id] : 3;
            return {
                ...prev,
                [currpost.id] : prevCounter + 3
            }
        })
        // if (!showMoreComments.includes[currpost.id]){

        //     // setShowMoreComments(prev => [...prev, currpost.id])
        //     // setShowMoreCommentsCounter(prev => prev + 1)
        // }
    }

    // function to display list of comments on posts
    const displayComments = (post) => {


        const currPostComments = commentCounter.filter((comment, index) => comment.post === post.id && comment.parent === null)

        const showMoreCommentsCounter = showMoreComments[post.id] || 2;
        const displayCommentsFeeds = currPostComments.slice(0, showMoreCommentsCounter)

        
            return ( 
                <>
            {   displayCommentsFeeds.map((comment, index) => (
                <CommentFeed commentCounter={commentCounter} 
                key={index}
                loggedInUser={loggedInUser} 
                comment={comment} 
                commentResponse={commentResponse} 
                post={post} 
                index={index} 
                isAuth={isAuth}
                />)
    
            )}

            {
                currPostComments.length > showMoreCommentsCounter && (
                    <button className={styles['load-more-button']} style={{}} id={`post-${post.id}`} onClick={() => handleShowMoreComments(post)}>
                        Load more comments
                    </button>
                    )
            }
            </>

            )


    
    }




    useEffect(()=>{
        APIContents.GetComment(auth)
        .then((resp) => {
            setCommentCounter(resp)
            props['refresh']()
        })


    
    }, [commented])


    const chatUser = async (loggedInUser, postUser) => {
        const defaultUser = 'test';

    
        const chatusers = {
            receiver: postUser,
        };
    
        if (loggedInUser === undefined || loggedInUser === null) {
            loggedInUser = defaultUser;
        }
    
        try {
            const resp = await APIContents.Chat(chatusers, auth);
            navigate(`/chats/${resp.uniquecode}`);
        } catch (error) {
            console.error(error);
            // Handle the error, e.g., show a notification or log it
        }
    };


    // const chatUser = async (loggedInUser, post_user)=> {


    //     if (loggedInUser !== undefined && loggedInUser !== null){
    //         const chatusers = {
    //             receiver: post_user,
    //         }
    //         APIContents.Chat(chatusers, auth)
    //         .then((resp) =>
    //         {
    //             console.log(resp)
    //             // navigate(`/chat/${resp.uniquecode}`)
    //         }
    //             )
    
    //         // navigate(`/chat/${post_user}&${loggedInUser}`)
            
    //     } else {
    //         loggedInUser = 'test'
    //         const chatusers = {
    //             receiver: post_user,
    //         }
    //         APIContents.Chat(chatusers, auth)
    //         .then((resp) =>
    //         {
    //             navigate(`/chat/${resp.uniquecode}`)
    //         }
    //             )

    //     }
    // }

    const isPostLiked = (postId, loggedInUser)=>{
        if (likesChecker !== null && likesChecker !== undefined){
            return likesChecker.some(item => item.post_id === postId && item.username.username === loggedInUser && item.action === true);
        }
    }

    useEffect(()=>{
        if (loggedInUser !== "anonymous" && loggedInUser !== '')
        { 
            APIContents.CheckLikePost(auth)
            .then((resp) => {
                // resp.forEach(element => {
                    setLikesChecker(resp);
                // });
                value.refresh()
            })
        
        }

    }, [liked])



  const likePost = (e) => {
   
    
    const like_button = document.getElementById(e)

    var action = false


    // const newLike = { 
    //     action: !action, 
    //     post_id: like_button.id,
    //     username: {
    //         username: loggedInUser, 
    //     },   
    // }
    // if (likesChecker !== undefined){
    //     setLikesChecker(prev => [...prev, newLike]);
    // }


    const content = {
        post_id: like_button.id,
        // username: 1,
    }
    APIContents.LikePost(content, auth)
    .then((resp)=>{
        if (resp !== null && resp !== undefined) {
            const likeIcon = document.getElementById(`${resp.post_id}`)
            var likeCouter = document.getElementById(`like-counter-${resp.post_id}`)
            
            if (resp.action === true){
            likeIcon.style.color = 'red'
            setLiked(true)
            }else{
                likeIcon.style.color = 'green'    
                setLiked(false)
            }


            setLiked(liked + 1);    

        }
    })


}
// window.addEventListener('', motion=()=> console.log('motion'))



    const groupedData = {};

    const formatedNumber = (number)=> {
        return number.toLocaleString()
    }

    const [hiddenPosts, setHiddenPosts] = useState([])


    const filteredPosts = (value.data || []).filter(post => {
    const isHidden = hiddenPosts.includes(post.post.id);

    if (isHidden){
        return false;
    }

    const categoryMatch = value.filters.category === '' || JSON.stringify(post).toLowerCase().includes(value.filters.category.toLowerCase());
    // const categoryMatch = value.filters.category === '' || post.post.category.some(value.filters.category).toLowerCase();
    const minPrice = value.filters.min === '' || post.post.price >= parseFloat(value.filters.min);
    const maxPrice = value.filters.max === '' || post.post.price <= parseFloat(value.filters.max);
    const state = value.filters.state === '' || JSON.stringify(post.post.user.profile.state).toLowerCase().includes(value.filters.state.toLowerCase());
    const city = value.filters.city === '' || JSON.stringify(post.post.user.profile.city).toLowerCase().includes(value.filters.city.toLowerCase());
    const delivery = value.filters.delivery === 'default' || JSON.stringify(post.post.delivery).toLowerCase().includes(value.filters.delivery.toLowerCase());
    return categoryMatch && minPrice && maxPrice && state && city && delivery;
});


    
    // const filteredPosts = value.data.filter(post => {
    //     const isHidden = hiddenPosts.includes(post.post.id);

    //     if (isHidden){
    //         return false;
    //     }

    //     const categoryMatch = value.filters.category === '' || JSON.stringify(post).toLowerCase().includes(value.filters.category.toLowerCase());
    //     // const categoryMatch = value.filters.category === '' || post.post.category.some(value.filters.category).toLowerCase();
    //     const minPrice = value.filters.min === '' || post.post.price >= parseFloat(value.filters.min);
    //     const maxPrice = value.filters.max === '' || post.post.price <= parseFloat(value.filters.max);
    //     const state = value.filters.state === '' || JSON.stringify(post.post.user.profile.state).toLowerCase().includes(value.filters.state.toLowerCase());
    //     const city = value.filters.city === '' || JSON.stringify(post.post.user.profile.city).toLowerCase().includes(value.filters.city.toLowerCase());
    //     const delivery = value.filters.delivery === 'default' || JSON.stringify(post.post.delivery).toLowerCase().includes(value.filters.delivery.toLowerCase());
    //     return categoryMatch && minPrice && maxPrice && state && city && delivery;
    // })

    const hidePost = (postId) => {
        console.log('hide post function')
        // setHiddenPosts(prevHiddenPosts => [...prevHiddenPosts, postId]);
        setHiddenPosts(prev => [...prev, postId])
    }


    const [sortPosts, setSortPosts] = useState(true)
    useEffect(()=>{
        // const sortPost = (post) =>  {
            if (value.filters.category === ''){
                setSortPosts(true)
            } else if (value.filters.category !== ''){
                setSortPosts(value.filters.category && value.data)
            }
        // }
    
    }, [value.filters])

    const [menuIsOpen, setMenuIsOpen]  = useState(false)


    function postDropDownMenu(postid) {
        console.log('dropdown')


        if (postid !== 'empty')
        {
            const id = document.getElementById(postid);

    
      
            if (!menuIsOpen && (id !== null || id !== undefined)){
                if ( id.style.display === 'flex'){
                    setIsMenuOpen('empty')
                    id.style.display = 'none'
                }  else {
                    id.style.display = 'flex'
                }    
            } else{
                setIsMenuOpen('empty')
            }
    
        }
        else {
            return null
        }
        // id.style.display = id.style.display === 'none' ? 'flex' : 'none';
      }
      
      const deletePost = (postid) => {
        console.log('delete function')
        const deleteNow = {
            postid: postid
        }

        APIContents.DeletePost(deleteNow, auth)
        .then(resp=>{
            // console.log(resp);
                props['refresh']();
                })
        
        
    }


      const sharePost = async (post) => {
        console.log('share function')
        try {
            if (navigator.share) {
                const domain = window.location.origin;
                const post_url = `${domain}/?postId=${post.id}`;
                await navigator.share({
                    title: 'Check out this post!',
                    text: post.description,
                    // url: window.location.href 
                    url: post_url 
                });
            } else {
                alert('Sharing is not supported on this device.');
                value.refresh()
            }
        } catch (error) {
            alert('Error sharing:', error);
        }
    };
    

// useEffect(() => {
//     const handleDocumentClick = (event) => {
//         const clickedInsideMenu = event.target.closest('.drop-menu') !== null;
//         if (!clickedInsideMenu) {
//             const menus = document.querySelectorAll('.drop-menu');
//             menus.forEach((menu) => {
//                 menu.style.display = 'none';
//             });
//         }
//     };

//     document.addEventListener('click', handleDocumentClick);

//     return () => {
//         document.removeEventListener('click', handleDocumentClick);
//     };
// }, []);




        // const ShuffledPosts = shuffleArray(filteredPosts);

        const [groupedDataFilled, setGroupedDataFilled] = useState(false)

        filteredPosts.forEach((item) => {
            const postId = item.post.id;
            if (!groupedData[postId]){

                groupedData[postId] = {
                    post : item.post,
                    image : [item.image],
                }
                // groupedData[postId] = [item];
                // groupedData[postId][0]['image'] = []
                // groupedData[postId]['image'] = [item['image']]



            } else
            {
                groupedData[postId].image.push(item['image'])

                // groupedData[postId][0]['image'].push(item["image"])
                // console.log(`other images ${item['image']}`)

            }

            if (groupedDataFilled || groupedData === undefined || groupedData == {}){

            } else {
                setGroupedDataFilled(true)
            }
        });

        console.log(groupedData)

    useEffect(()=> {
        if ((singlePostId !== null || singlePostId !== undefined) && ( groupedData !== undefined || groupedData !== null)  ){
            // const data = [groupedData]
            const resultExists = [groupedData].some(item => item[singlePostId])
            if (resultExists){
                setSinglePostPopUp(true)
                setSinglePostContent(groupedData[singlePostId])
                // const result = data.find(post => post[singlePostId])
            }
        } else {
            setSinglePostPopUp(false)
        }

    }, [singlePostId, groupedDataFilled, singlePostPopUp])

       

    const [isMenuOpen, setIsMenuOpen] = useState('empty');

    
  useEffect(() => {
	  const handleClickOutside = (event) => {
		// if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (menuRef.current && event.target !== menuRef.current) {
            postDropDownMenu(isMenuOpen)



        }
	  };

        if (isMenuOpen !== 'empty'){
            setMenuIsOpen(true)
            // postDropDownMenu(isMenuOpen)
            document.addEventListener('click', handleClickOutside);
            console.log("add event listener")
        }
        else {
            setMenuIsOpen(false)
            // postDropDownMenu(isMenuOpen)
            document.removeEventListener('click', handleClickOutside);
            console.log("remove event listener")
        }

        return () => {
        document.removeEventListener('click', handleClickOutside);
      };  
	}, [isMenuOpen]);


    // useEffect(() => {
    //     setLoading(true)
    //     }, [])

    const initiateCall = (number) => {
        window.location.href =  `tel:${number}`
    }
    //function to split the posts description
    const [showAll, setShowAll] = useState('')


    const splitText = (text) => {
        return text.split(' ')
    } 

    const handleReadMore = (post) =>{
            setShowAll(post.id)
    }

    const displayDescription = (post)=> {
        const words = splitText(post.description)
        if (showAll === post.id || words.length <= 10){
            return words.join(' ')
        }
        else {
            const truncatedTexts = words.slice(0, 15).join(' ')
            return (<>
                {truncatedTexts}... 
                <button 
                style={{backgroundColor: 'transparent', color: '#71a330', 
                border: 'none', fontSize: '10px'}} 
                onClick={(e)=> handleReadMore(post)}>
                    Read More... </button>
                </>
            )
        }

    }

    return (
        <div className="post-content">
            
            <CreatePost props = {props} loggedInUser = {MainloggedInUser}/>
            {
                Object.keys(groupedData) && Object.keys(groupedData).length > 0  ? (
                    <div className="post-item">


                    {/* "/static/images/defaultpic.png" */}
                        {
        
                            Object.keys(groupedData).map((groupId, index) =>{
                                
                                const groupPost = groupedData[groupId]
                                const post = groupPost.post
                                const images = groupPost.image
        
                                        return ( 
                                 <div key={`${post.id}_${index}`} className="post-loop">
                                        <div>
                                        {groupPost[images]} 
                                        </div>
        
                                    <div className="post-header">
                                        <div className="icon-link">
                                                <div className="post-icon">  
                                                    <a href={`/profile/${post.user.username}`}>
                                                    <img src={`${BASE_URL}${post.user.profile.profileimg}`} 
                                                    className="profile-img" 
                                                    alt=''
                                                    onError={(e)=> {
                                                        e.target.src = "/static/images/defaultpic.png"
                                                    }}
                                                    /> </a>
                                                </div>
                            
                                                <div className="profile-det">
                                                    <span className="post-user-link"><a href={`/profile/${post.user.username}`}>
                                                    {
                                                         (post.user.vendor === null || post.user.vendor === undefined || (post.user?.vendor?.marketname === null || post.user?.vendor?.marketname === '')) ? 
                                                         (( post.user.last_name !== '' || post.user.first_name !== '' ) ? `${post.user?.first_name} ${post.user?.last_name}` : post.user.username)
                                                           : post.user?.vendor?.marketname
                                                        }                     
                                                        
                                                    </a>
        
                                                    {
                                                        isAuth && loggedInUser === post.user.username ?
                                                        <>
                                                        </> 
                                                        : isAuth && loggedInUser !== post.user.username ?
                                                        <>
        
                                                        {
                                                            isFollowing(post.user.username) === true 
                                                            ? 
                                                            <span style={{color: "gray", fontSize:"small"}} >- following</span>
                                                            :
                                                            <button id="post-followers-form" className="follow-button" onClick={()=> follow(post.user.username, loggedInUser)} >Follow</button>
        
        
                                                        }
        
        
        
                                                        </> : <>
                                                        </>
                                                    }
        
                                                         {/* <form className="post-followers-form" action="/follow" method="POST">
                                                                  <input type="text" name="user-id" id="user-id" readOnly value="lazystu" hidden/>
                                                                  <button id="post-followers-form" className="follow-button" type="submit">Follow</button>
                                                          </form> */}
                                             
                                                     </span>
                                                     <span className="post-user-link2"><a href={`/profile/${post.user.username}`}>@{post.user.username}
                     
                                                     </a>
                                                        </span>
                                                </div>
                                        </div>
                            
                                        {/* <div onClickCapture={() => postDropDownMenu(`menu-${post.id}`)} className="drop"> */}
                                        <div ref={imgRef} onClick={() => setIsMenuOpen(`menu-${post.id}`)} className="drop">
                                        <img   src="/static/images/menuicon.png" alt="menuicon" />
        
                                        </div>
                                        {
                                            isMenuOpen !== 'empty' && isMenuOpen === (`menu-${post.id}`) && (
                                                <div id={`menu-${post.id}`} className='drop-menu' ref={menuRef}>
                                                {
                                                    isAuth & loggedInUser === post.user.username ? <>
                                                                                    <div onClick={()=> deletePost(post.id)} className='each'>
                                                <img width="20px" height="20px"  src="https://img.icons8.com/color/48/minus.png" alt="delete-sign--v1"/>
                                                <p className='drop-menu-item' > Delete </p>
                                                </div>
                                                    </> : isAuth && loggedInUser !== post.user.username ? <>
                                                    <div className='each' onClick={(e)=> hidePost(post.id)}>
                                                <img width="20px" height="20px" src="   " alt="delete-sign--v1"/>
                                                <p className='drop-menu-item' > Hide </p>
                                                </div>
                                                    
                                                    </> :
                                                    <></> 
                                                }
                                                <div className='each' onClick={() => sharePost(post)}>
                                                <img width="20px" height="20px" src="https://img.icons8.com/color/48/share--v1.png" alt="delete-sign--v1"/>
                                                <p className='drop-menu-item'> Share </p>
                                                </div>
            
                                            </div>
        
                                            )
                                        }
         
                                    </div> 
                                    <div className="description">
                                        <p>{
                                            displayDescription(post)
                                            }
                                            </p>
                                    </div>    
                                    <div className="image-container">
        {/* <a href='/'> */}
          {/* {images !== undefined && images.map((item, index) => {
        
            if (images.length === 1) {
              return (
                <img key={index} className="post-image" src={`${BASE_URL}${item}`} alt="First Img" style={{ width: '100%', height: '100%' }} />
              );
            } else if (images.length === 2 || images.length === 3) {
              // Split width and height for 2 or 3 images
              return (
                <img key={index} className="post-image" src={`${BASE_URL}${item}`} alt={`Img ${index}`} style={{ width: '48%', height: 'auto', margin: '1%' }} />
              );
            } else if (images.length === 4) {
              // Split width and height for 4 images
              return (
                <img key={index} className="post-image" src={`${BASE_URL}${item}`} alt={`Img ${index}`} style={{ width: '48%', height: '48%', margin: '1%' }} />
              );
            } else if (images.length > 4 && index === 3) {
              // Display a + icon for more than 4 images in the last image
              return (
                <div key={index}  className="post-image" style={{ width: '50%', height: '50%' }}>
                <img src={`http://localhost:8000${item}`} alt={`Img ${index}`} style={{ width: '100%', height: '100%' }} />
                <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(0, 0, 0, 0.5)' }}>
                  <span style={{ color: 'white', fontSize: '24px' }}>+</span>
                </div>
                </div>
              );
            } else if (index < 4) {
              // Display the first 4 images
              return (
                <img className="post-image" src={`http://localhost:8000${item}`} alt={`Img ${index}`} style={{ width: '50%', height: '50%' }} />
              );
        
            }
        })} */}
            {images.length === 1 ? (
                  // If there's only one image, display it without the slider
                  <img
                  onClick={() => setSinglePostId(post.id)}
                    className="post-image"
                    src={`${BASE_URL}${images[0]}`}
                    alt="First Img"
                    // style={{width: "500px", height: "500px"}}
                    />
                ) : (
                  // If there are more than one image, use the Carousel component
                  <Carousel
                  autoPlay={true}
                //   autoPlay={index === selectedPostIndex}
                //   onClick={handlePostChange}
                  showThumbs={false}
                  showArrows={true}
                  showStatus={false}
                  infiniteLoo={true}
                  dynamicHeight={true}
                  interval={3500} // Auto slide every 1.5 seconds
                >
                  {images.map((item, index) => (
                    <div onClick={() => setSinglePostId(post.id)} key={index}>
                      <img
                        className="carousel-image"
                        src={`${BASE_URL}${item}`}
                        alt={`Img ${index}`}
                        // style={{width: "500px", height: "500px"}}
                        />
                    </div>
                  ))}
                </Carousel>
                )}
        
        {/* </a> */}
                                    </div> <hr/>
                                    <div className="activity-button"> 
                                        <div className="top-activity">
                                            <div className="like-comment">
        
                                                   {
                                                    isAuth ? <>
                                                        <svg onClick={() => likePost(post.id)} id={post.id} style={{color: isPostLiked(post.id, loggedInUser) === true ? 'red' : 'green', margin: '0 5px 5px 0 ', cursor: 'pointer'}} className='like-button' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="25" height="25" >
                                                      <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"></path>
                                                    </svg>
                                                    </> : <></>
                                                   }
                                                   {
                                                    isAuth && loggedInUser !== post.user.username ? <>
    
                                                    {/* <Link to={}></Link> */}
                          
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={() => chatUser(loggedInUser, post.user.username)} fill="currentColor" style={{width :'25px', height: '25px', cursor: 'pointer'}} className="">
                                                            <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd"></path>
                                                        </svg>

                                                        {
                                                            (post.user?.vendor?.display_phone) ? 
                                                            <svg viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg" style={{ width: '25px', height: '25px' }} onClick={() => initiateCall(post.user.profile.phone)}>
                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.3545 22.2323C15.3344 21.7262 11.1989 20.2993 7.44976 16.5502C3.70065 12.8011 2.2738 8.66559 1.76767 6.6455C1.47681 5.48459 2.00058 4.36434 2.88869 3.72997L5.21694 2.06693C6.57922 1.09388 8.47432 1.42407 9.42724 2.80051L10.893 4.91776C11.5152 5.8165 11.3006 7.0483 10.4111 7.68365L9.24234 8.51849C9.41923 9.1951 9.96939 10.5846 11.6924 12.3076C13.4154 14.0306 14.8049 14.5807 15.4815 14.7576L16.3163 13.5888C16.9517 12.6994 18.1835 12.4847 19.0822 13.1069L21.1995 14.5727C22.5759 15.5257 22.9061 17.4207 21.933 18.783L20.27 21.1113C19.6356 21.9994 18.5154 22.5232 17.3545 22.2323ZM8.86397 15.136C12.2734 18.5454 16.0358 19.8401 17.8405 20.2923C18.1043 20.3583 18.4232 20.2558 18.6425 19.9488L20.3056 17.6205C20.6299 17.1665 20.5199 16.5348 20.061 16.2171L17.9438 14.7513L17.0479 16.0056C16.6818 16.5182 16.0047 16.9202 15.2163 16.7501C14.2323 16.5378 12.4133 15.8569 10.2782 13.7218C8.1431 11.5867 7.46219 9.7677 7.24987 8.7837C7.07977 7.9953 7.48181 7.31821 7.99439 6.95208L9.24864 6.05618L7.78285 3.93893C7.46521 3.48011 6.83351 3.37005 6.37942 3.6944L4.05117 5.35744C3.74413 5.57675 3.64162 5.89565 3.70771 6.15943C4.15989 7.96418 5.45459 11.7266 8.86397 15.136Z" fill="#0F0F0F"></path>
                                                            </g>
                                                        </svg> : <></>
                                                            }
    
                                                    </>: !isAuth && post.user?.vendor?.display_phone ? <>
                                                    <svg viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg" style={{ width: '25px', height: '25px' }} onClick={() => initiateCall(post.user.profile.phone)}>
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.3545 22.2323C15.3344 21.7262 11.1989 20.2993 7.44976 16.5502C3.70065 12.8011 2.2738 8.66559 1.76767 6.6455C1.47681 5.48459 2.00058 4.36434 2.88869 3.72997L5.21694 2.06693C6.57922 1.09388 8.47432 1.42407 9.42724 2.80051L10.893 4.91776C11.5152 5.8165 11.3006 7.0483 10.4111 7.68365L9.24234 8.51849C9.41923 9.1951 9.96939 10.5846 11.6924 12.3076C13.4154 14.0306 14.8049 14.5807 15.4815 14.7576L16.3163 13.5888C16.9517 12.6994 18.1835 12.4847 19.0822 13.1069L21.1995 14.5727C22.5759 15.5257 22.9061 17.4207 21.933 18.783L20.27 21.1113C19.6356 21.9994 18.5154 22.5232 17.3545 22.2323ZM8.86397 15.136C12.2734 18.5454 16.0358 19.8401 17.8405 20.2923C18.1043 20.3583 18.4232 20.2558 18.6425 19.9488L20.3056 17.6205C20.6299 17.1665 20.5199 16.5348 20.061 16.2171L17.9438 14.7513L17.0479 16.0056C16.6818 16.5182 16.0047 16.9202 15.2163 16.7501C14.2323 16.5378 12.4133 15.8569 10.2782 13.7218C8.1431 11.5867 7.46219 9.7677 7.24987 8.7837C7.07977 7.9953 7.48181 7.31821 7.99439 6.95208L9.24864 6.05618L7.78285 3.93893C7.46521 3.48011 6.83351 3.37005 6.37942 3.6944L4.05117 5.35744C3.74413 5.57675 3.64162 5.89565 3.70771 6.15943C4.15989 7.96418 5.45459 11.7266 8.86397 15.136Z" fill="#0F0F0F"></path>
                                                        </g>
                                                    </svg>
    
    
                                                    </> :<>
        
                                                    
                                                    </>
                                                   }
                                              </div>

        
                                            <div className="price">
                                                <a className="price-link" href='/'>Item Price: N {formatedNumber(post.price)}</a>
                                            </div>
        
                                        </div>
                                        <div className="liked-b"> 
        
                                            <div className="">
                                        </div>
                                        </div>
                                        <div id={`like-counter-${post.id}`} className="liked-by">          
                                        {
                                            post.no_of_likes === 0 ?
                                            <>
                                            </> : post.no_of_likes === 1 ?
                                            <>
                                            {post.no_of_likes} like
                                            </> : <>
                                            {formatedNumber(post.no_of_likes)} likes
                                            </>
        
                                        }
                                        </div>
        
                                        <p className="caption">
                                        <a href={`/profile/${post.user.username}`}><strong>{post.user.username}: </strong></a>{post.caption}
                                    </p>
        
                                    <CreateComment loggedInUser={loggedInUser} MainloggedInUser={MainloggedInUser} BASE_URL={BASE_URL} commentResponse={commentResponse} post={post} isAuth={isAuth} />
        
                                            <div className={styles['comment-feeds']}>
                                                
                                                {commentCounter !== null && commentCounter !== undefined && commentCounter.length > 0 ? (
                                                    <>
                                                {
                                                    displayComments(post)
                                                }
        
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )}        
                                                </div>
                                        
                                        
                                        
                            
                                    </div>
                            
                                </div>
                            
                                )
                            }) 
                        }
        
            <p></p>
        
                    </div>
    
                )
                : (
                    <>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <div className={styles["no-content"]}>
                        No Content Found... </div>
                    </>
                )
            }

                {
                (singlePostPopUp && singlePostContent !== undefined) ?
                <>
                <SinglePostPopup singlePostContent={singlePostContent} setSinglePostPopUp={setSinglePostPopUp} setSinglePostId={setSinglePostId} />
                </> : <></>
             }


        </div>
  
    )
};

export default PostContents;