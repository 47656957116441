import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Create a CSS file for styling
import OuterNav from '../../components/navigation/outer-nav';

const NotFound = () => {
  return (
    <>
    <OuterNav />
    <div className="not-found-container">
      <img src="/static/images/logo.png" alt="Social Media App Logo" className="logo" />
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for might be under construction or does not exist.</p>
      <Link to="/">Go to Home</Link>
    </div>
    </>    
  );
};

export default NotFound;
