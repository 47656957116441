import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function ChatSettings ({userProfile, BASE_URL, formData, setFormData, setImage, UpdateDataBtn, activationToggle }) {

    

    const navigate = useNavigate()
    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 
    useEffect(()=> {
        if (userProfile.currentuser){
            const user = userProfile.currentuser;
            setFormData({
                ...formData,
                chat_color_toggle: user?.background_color,
            })
        }
    }, [userProfile]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name] : value
        });

    };


    return (
        userProfile.currentuser !== undefined && (
            <div className="entire-body">
            <div id="head-content" className="head-content">
                <span className="go-back">
                </span>
                <h4>
                    {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                    <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                    {/* </a> */}
                    Chat Settings</h4>
            </div> 
            <div className="profile-top">
            <h3>Change Chat wallpaper</h3>
                <div className="profile-cover-img">
                    <img  id="profile-cover" src={userProfile.currentuser.wallpaper ? BASE_URL + '/media/' + userProfile.currentuser.wallpaper : BASE_URL + '/media/backgroundimg.png'} className="major-img" />
                    {/* <img id="profile-cover" src="{{user_profile.coverimg.url}}" alt="" className="major-img" /> */}
                    {/* <img  id="profile-cover" src="media/defaultpic.png" alt="" />
                    <img src="/static/images/uploadicon.png" alt=""  className="hover-img"/> */}
                </div>
                <form>
                    <label htmlFor="coverimg">Wallpaper:</label>
                    <input type="file" accept="image/*" name="coverimg" onChange={e => setImage(e.target.files)} id="cover-pic-upload"/> 
                    <button id="wallpaper-btn" type="submit" onClick={(e) => UpdateDataBtn(e)}>Update</button>
                </form>
            </div>
    
            <div className="profile-top">
                <h3>General</h3>
                <div className="personal-info">

                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">
                    {/* <label htmlFor="wallet-toggle" > Enable escrow service</label> */}
                    <label htmlFor="chat_color_toggle" > Change default color</label>
                    <input style={{width: '200px', border: `2px solid ${formData.chat_color_toggle} `}} type="text" value={formData.chat_color_toggle}  onChange={(e) => handleInputChange(e)}
                    name="chat_color_toggle" id="chat_color_toggle"/>

                    </div>


                    {/* <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">

                    <label htmlFor="display_bio-toggle" > Display Bio</label>
                     <input  style={{width: "fit-content"}} name="display_bio-toggle" id="display_bio-toggle" type="checkbox" checked={userProfile.currentuser.display_bio} onChange={e => activationToggle(e)} />
                    </div> */}
                    <button id="chat-general-btn" type="submit" onClick={(e) => UpdateDataBtn(e)}>Update</button>

                     <div className="transactions">
                     </div>
    
                      
                </div>
            </div>

        </div>
    
        )

    //     <div id="chat">
    //     <h4 style={{textAlign: "center", margin: "50% 0"}}>This page is Under Construction!!!
    //         {/* <a href="" onClick={handleGoBack}> */}
    //             <button style={{border: "none", marginTop: "20px"}}>
    //                 <img style={{width: "20px", height: "15px"}} src="/static/images/go-back.svg" alt=""/>
    //                 GO BACK</button>
    //         {/* </a> */}
    
    //     </h4>
    // </div>

    )
}

export default ChatSettings;