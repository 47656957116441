import React, { useEffect, useState } from 'react';
import MainNavBar from '../../components/navigation/second-nav';
import APIContents from '../../api/api';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Notification = () => {
  let token = useCookies('loginToken')
  let auth =  token[0]['loginToken']


  let loggedInUserToken  = useCookies('username')
	const [loggedInUser, setloggedInUser] = useState('')

  useEffect(()=> {
    if (loggedInUserToken !== undefined) {
        setloggedInUser(loggedInUserToken[0]['username'])
    }
    else {
        setloggedInUser('anonymous')
    }
}, [loggedInUserToken])


  const [notifications, setNotifications] = useState({})
  useEffect(()=> {
    APIContents.getNotifications(auth, loggedInUser)
    .then(resp => setNotifications(resp.data))
  }, [])

  // console.log(notifications[0]?.user[0]?.background_color)
  return (    
    <>
    <MainNavBar/>
        
        {
          notifications[0]?.user[0] &&
          <div className="chat-body">
          <div className="chat-body-c">
          <div style={{backgroundColor : notifications[0]?.user[0]?.background_color !== null ? notifications[0]?.user[0]?.background_color : ''}}  className="chat-body-outer">
              <div className="chat-userinfo">
                <div className="chat-convo-header">
                  <h2 style={{ marginLeft: '20px', textAlign: 'center', color: 'white' }}>Notifications</h2>
                </div>
              </div>
            </div>
    
            <div style={{overflowY: "auto"}} className="chat-container">
              <div className="main-row">
                {
                  !notifications ? <>
                  {
                    Object.keys(notifications) && Object.values(notifications).map((item, index)=> (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '5px 10px',
                        backgroundColor: 'rgb(242, 242, 242)'
                      }} className='each_notification'>
                          <div style={{backgroundColor: "#f2f2f2"}} className="sideBar-body">
                            <div className="sideBar-main">
                              <div style={{display: "flex", flexDirection : "column"}} className="user-message">
                              <span className="name-icon"><strong>{(item.title)}</strong>: { moment.utc(item.timestamp).local().startOf('seconds').fromNow()}</span>
                              <span> <strong>IP Address:</strong> {item.ip_address}</span>
                              <span> <strong>Device:</strong> {item.device}</span>
                              </div>
                            </div>
                          </div>
    
                      </div>
    
                    ))
                  }
                  </> 
                  :
                  <div className="chat-name-list">
                  <h5 id="nomessage">You don't have any Notification yet.</h5>
                </div>
    
                }
              </div>
            </div>
          </div>
        </div>
            }

    </>
  );
};

export default Notification;
