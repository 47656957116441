import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function MarketPlaceSettings ({userProfile, BASE_URL, auth, formData, setFormData, UpdateDataBtn, activationToggle}) {

    const navigate = useNavigate()

    // const handleGoBack = ()=> {
    //     const defaultTab = document.getElementById('default'); 
    //     defaultTab.style.display = 'flex'
    //     console.log('updated now ')
    //     // changeTab(defaultTab);    
    // } 
    const handleGoBack = ()=> {
        // const defaultTab = document.getElementById('default'); 
        // defaultTab.style.display = 'flex'

        navigate('/settings')        
        
    } 

    useEffect(()=> {
        if (userProfile.currentuser){
            const user = userProfile.currentuser;
            setFormData({
                ...formData,
                biz_name: user?.market_info?.marketname,
                reg_no: user?.market_info?.reg_number,
                biz_phone: user?.market_info?.phone,
                biz_address: user?.market_info?.address,
                biz_city: user?.market_info?.city,
                biz_state: user?.market_info?.state,
                biz_country : user?.market_info?.country,

                bank_name : user?.bank_accounts[0]?.bank_name,
                account_name : user?.bank_accounts[0]?.account_name,
                account_number : user?.bank_accounts[0]?.account_number,

                display_phone : user?.market_info?.display_phone,
                allow_comments : user?.market_info?.allow_comments,
                allow_multi_image : user?.market_info?.allow_multi_image,

            });
        }
    }, [userProfile]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name] : value
        });

    };

    return ( 
        userProfile && userProfile.currentuser !== undefined && (
        <div className="entire-body">
        <div id="head-content" className="head-content">
            <span className="go-back">
            </span>
            <h4>
                    {/* <a href="/settings" onClick={handleGoBack} style={{textDecoration: "none"}}> */}
                    <img onClick={handleGoBack} style={{width: "40px", marginRight: "20px", height: "20px"}} src="/static/images/go-back.svg" alt=""/>
                    {/* </a> */}
                MarketPlace Settings</h4>
        </div>
        {
            userProfile.currentuser?.market_info !== undefined && userProfile.currentuser?.market_info !== null ? <>
        <div className="profile-top">
            <h3>Vendor Details</h3>
            <div className="personal-info">
                {/* <form action="/settings" method="post"> */}
                    <label htmlFor="">Business Name:</label>
                    <input type="text" name="biz_name" value={formData.biz_name} onChange={handleInputChange} id=""/>
                    <label htmlFor="reg_number">Reg Number (optional):</label>
                    <input  type="text" value={formData.reg_no} name="reg_no" onChange={handleInputChange} id=""/>

                    <label htmlFor="biz_phone">Phone Number:</label>
                    <input type="text" value={formData.biz_phone} onChange={handleInputChange}
                    name="biz_phone" id="biz_phone"/>
                    <label htmlFor="biz_country">Country:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="biz_country" id="biz_country" value={formData.biz_country} onChange={handleInputChange}>
                        <option> -- select a country -- </option>
                        <option value="nigeria">Nigeria</option>
                        <option value="others"> Others</option>
                        </select>

                    <label htmlFor="biz_state">State:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="biz_state" id="biz_state" value={formData.biz_state} onChange={handleInputChange}>
                        <option> -- select a state -- </option>
                        <option value="FCT-Abuja">FCT-Abuja</option>
                        <option value="Lagos">Lagos</option>
                         </select>

                         <label htmlFor="business_city">City:</label>
                        <input type="text" name="biz_city" onChange={handleInputChange} value={formData.biz_city} id=""/>

                    {/* <label htmlFor="delivery_time">O</label>

                    <p style={{fontSize: "10px"}}>Note: please upload in pdf or zip format your registration documents to get verified easily. e.g cac documents etc.</p>
                    <input readOnly type="file" accept="pdf, zip" name="other_documents" id="other_documents"/> */}
                    <button type="submit" id="vendor-details" onClick={e => UpdateDataBtn(e)}>Update</button>
                {/* </form> */}
            </div>
            </div>
            <div className="profile-top">
                <h3>Bank Details</h3>
                <div className="personal-info">
                    <form action="">
                        <label htmlFor="bank_name">Bank Name:</label>
                        <input type="text" name="bank_name" id="bank_name" value={formData.bank_name} onChange={handleInputChange} />
                        <label htmlFor="account_name">Account Name:</label>
                        <input type="text" name="account_name" id="account_name" value={formData.account_name} onChange={handleInputChange}/>
                        <label htmlFor="account_number">Account Number:</label>
                        <input type="number" name="account_number" id="account_number" value={formData.account_number} onChange={handleInputChange} />
                        <button type="submit" id="bank-details-update" onClick={e => UpdateDataBtn(e)} >Update</button>
                    </form>
                </div>
            </div>
            <div className="profile-top">
                    <h3>Verification Badge</h3>
                    
                <div className="personal-info">
                    <a style={{color: "white", textDecoration: "none"}} href="https://flutterwave.com/pay/nearbyvendorbadge">
                        <button className="verification">
                        ACTIVATE
                    </button>
                    </a>
                    {/* <span>Past Transactions</span> */}
                     <div className="transactions">
                     </div>
    
                      
                </div>
            </div>
            <div className="profile-top">
                <h3>Advanced Settings</h3>
                <div className="personal-info">

                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">

                    <label htmlFor="allow_comment-toggle" > Allow users to comment</label>
                     <input  style={{width: "fit-content"}} name="allow_comment-toggle" id="allow_comment-toggle" type="checkbox" 
                     checked={userProfile.currentuser.market_info.allow_comments} onChange={e => activationToggle(e)} />
                    </div>

                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">

                    <label htmlFor="multi_image-toggle" > Enable multi-image upload</label>
                     <input  style={{width: "fit-content"}} name="multi_image-toggle" id="multi_image-toggle" type="checkbox" 
                     checked={userProfile.currentuser.market_info.allow_multi_image} onChange={e => activationToggle(e)} />
                    </div>

                    <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 20px 0 0',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                    className="div-2">

                    <label htmlFor="display_phone-toggle" > Display Phone Number on posts</label>
                     <input  style={{width: "fit-content"}} name="display_phone-toggle" id="display_phone-toggle" type="checkbox" 
                     checked={userProfile.currentuser.market_info.display_phone} onChange={e => activationToggle(e)} />
                    </div>


                </div>
            </div>
        
            
            </> : <>
            <div className="profile-top">
                <h3>SETUP A STORE</h3>
                <div className="personal-info">
                {/* <form action="/settings" method="post"> */}
                    <label htmlFor="">Business Name:</label>
                    <input type="text" name="biz_name" value={formData.biz_name} onChange={handleInputChange} id=""/>
                    <label htmlFor="reg_number">Reg Number (optional):</label>
                    <input  type="text" value={formData.reg_no} name="reg_no" onChange={handleInputChange} id=""/>

                    <label htmlFor="biz_phone">Phone Number:</label>
                    <input type="text" value={formData.biz_phone} onChange={handleInputChange}
                    name="biz_phone" id="biz_phone"/>
                    <label htmlFor="biz_country">Country:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="biz_country" id="biz_country" value={formData.biz_country} onChange={handleInputChange}>
                        <option> -- select a country -- </option>
                        <option value="nigeria">Nigeria</option>
                        <option value="others"> Others</option>
                        </select>

                    <label htmlFor="biz_state">State:</label>
                    <select style={{
                        width: '100%',
                        margin: '5px 0px',
                        border: '1px solid darkgray',
                        padding: '10px'
                    }} name="biz_state" id="biz_state" value={formData.biz_state} onChange={handleInputChange}>
                        <option> -- select a state -- </option>
                        <option value="FCT-Abuja">FCT-Abuja</option>
                        <option value="Lagos">Lagos</option>
                         </select>

                         <label htmlFor="business_city">City:</label>
                        <input type="text" name="biz_city" onChange={handleInputChange} value={formData.biz_city} id=""/>

                    {/* <label htmlFor="delivery_time">O</label>

                    <p style={{fontSize: "10px"}}>Note: please upload in pdf or zip format your registration documents to get verified easily. e.g cac documents etc.</p>
                    <input readOnly type="file" accept="pdf, zip" name="other_documents" id="other_documents"/> */}
                    <button type="submit" id="vendor-details" onClick={e => UpdateDataBtn(e)}>Update</button>
                {/* </form> */}
            </div>
            </div>
            
            </>
        }
    
    

            

    
    </div>               
            )
      

    )
}

export default MarketPlaceSettings;