import React from 'react';
import styles from './singlepost.module.css';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router';

const SinglePostPopup = ({ singlePostContent, onClose, setSinglePostPopUp, setSinglePostId }) => {


    const BASE_URL = 'https://source.nearbyvendor.com'
    const post = singlePostContent

    const navigate = useNavigate();

    const closePost = () => {
        setSinglePostId('')
        setSinglePostPopUp(false)
        navigate('/')
    }
  return (
    <div className={styles['post-popup-overlay']} onClick={onClose}>
      <div className={styles['post-popup']}>
        <div className={styles['post-header']}>
          <h2>{singlePostContent.post.title}</h2>
          <button style={{borderRadius: '0px'}} className={styles['close-button']} onClick={() => closePost()}>
            X
          </button>
        </div>
        <div className={styles["image-container"]}>
        {post.image.length === 1 ? (
              // If there's only one image, display it without the slider
              <img
            //   onClick={() => setSinglePostId(post.id)}
                className={styles["post-image"]}
                src={`${BASE_URL}${post.image[0]}`}
                alt="First Img"
                // style={{width: "500px", height: "500px"}}
                />
            ) : (
              // If there are more than one image, use the Carousel component
              <Carousel
              autoPlay={true}
            //   autoPlay={index === selectedPostIndex}
            //   onClick={handlePostChange}
              showThumbs={false}
              showArrows={true}
              showStatus={false}
              infiniteLoo={true}
              dynamicHeight={true}
              interval={3500} // Auto slide every 1.5 seconds
            >
              {post.image.map((item, index) => (
                <div key={index}>
                  <img
                    // onClick={() => setSinglePostId(post.id)}
                    className="carousel-image"
                    src={`${BASE_URL}${item}`}
                    alt={`Img ${index}`}
                    // style={{width: "500px", height: "500px"}}
                    />
                </div>
              ))}
            </Carousel>
            )}
    
    {/* </a> */}
                                </div> <hr/>

        <div className={styles['post-content']}>
          <p>"{post.post.description}"</p>
          {/* Add more details based on your post structure */}
        </div>
      </div>
      
    </div>
  );
};

export default SinglePostPopup;
