import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import OuterFooter from "../../../components/footer/outer-footer";
import OuterNav from "../../../components/navigation/outer-nav";
import styles from '../register/register.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';


import ReactGA from 'react-ga';

// const BASE_URL = 'http://localhost:8000/api'
const BASE_URL = 'https://source.nearbyvendor.com/api/renderapi'
// const BASE_URL = 'https://nearbyvendor.onrender.com/api/renderapi'


const ResetPassword = () => {

    const location = useLocation();

    const searchParam = new URLSearchParams(location.search)

    const username = searchParam.get('username')
    // const { username } = useParams();

    useEffect(() => {
        // Track page view
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    
    
    document.title = "Account Verification"
    
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false)
    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    
    function notify(){
        setDisplay(true)
        setTimeout(()=> {
            setDisplay(false)
        },
        4000

        )
    
    }

    function handleResetPassword(e){
        e.preventDefault();

        const Details = {
            email : email
        }
        e.target.hidden = true
        setLoading(true)

        fetch(`${BASE_URL}/forgotpassword`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token}`
            },
            body: JSON.stringify(Details),

        })
        .then(resp => resp.json())
        .then((resp) => {
            setResponse(resp);
            if (resp['response'] !== 'successful'){
                notify()
                setLoading(false)
                e.target.hidden = false
            } else{
                navigate(`/login`)
                // navigate.push('/login')
            }
        })
        .catch(error=> console.log(error))
    }


    // if (password === password2 && username !== "" && storeName !== "")
    // {
    //     setformIsFIlled(true)
    // }


    return ( 
        
        <div className={styles.outerContainer}>
            {/* <OuterNav isLoggedIn={true} /> User is logged in */}
            <OuterNav/>
            {/* <div className="container"> */}
                <div className={styles.formContainer} style={{top: "20vh", minHeight: "74vh"}}>
                    <div className={styles.content}>
                    <h1 className={styles.formHead}> Reset your password</h1>
                    
                    <div>
                    <h5 style={{display: display ? 'block' : 'none'}} className={styles.error}>Email doesn't exists</h5>
                    </div>

                    

                    <form className={styles.form}>
                    <div className={styles.part}>
                    <input value={email} onChange={e => setEmail(e.target.value)} placeholder='enter email address' />
                    <button type="submit" onClick={(e)=> handleResetPassword(e)} className={styles.button}>RESET</button>
                    {
                        loading ? 
                        <button type="button" className={styles.button}>
                        <ClipLoader
                        // color={color}
                        loading={loading}
                        // cssOverride={override}
                        size={15}
                        // style={{margin: "auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                        </button>
    
                    //     <div style={{width: "inherit", display: "flex", height: "inherit", margin: "auto", alignItems: "center", justifyContent: "center"}}>
                    //   </div>

                      : 
                      <></>
                    }

                    </div>

                    {/* <div className={styles.test}>
                        <div className={styles.test}>
                            <input type="checkbox" name="merchant" id="chekcbox1" checked/>
                            <label htmlFor="chekcbox1"><span className={styles.test}></span> I Agree</label>
                        </div>
                        {/* <a href="#"> Terms and Conditions</a>
                    </div> */}
                    {/* <div className={styles.test}>
                        <p className={styles.test}> Do you have an account? </p>
                            {/* <a className={styles.test} href="/signin"> Login</a>
                    </div> */}
                </form>


                    </div>
                </div>

            {/* </div> */}
            <OuterFooter/>
        </div>
    )
}
export default ResetPassword;