import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './followers.modules.css';
import APIContents from '../../api/api';





function Followers({follow, followers, props}){
	// const BASE_URL = 'http://192.168.68.118:8000'
    const BASE_URL = 'https://source.nearbyvendor.com'
    // const BASE_URL = 'http://192.168.8.117:8000' 

    // const BASE_URL = 'https://nearbyvendor.onrender.com'

    const [users, setUsers] = useState([])





    // const [motion, setMotion] = useState('');
    let token = useCookies('loginToken')
    let auth =  token[0]['loginToken']
    const [isAuth, setIsAuth] = useState(false)
    
    let loggedInUserToken  = useCookies('username')
	let loggedInUser = loggedInUserToken[0]['username']






    useEffect(()=> {
		APIContents.Profiles(auth)
		.then(resp=> {
			setUsers(resp);		
		// const currentUser = resp.find(user => user.username === username )
		// setUserProfile(currentUser)
		})
		.catch(error => {
			console.log('error fetching data: ', error)
		})


	}, [auth]);

    useEffect(()=> {
        if (auth !== undefined && auth !== null){
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        } 
        // setAccounts(followers)
    }, [auth])


    const CurrLoggedInUser = users.find(user => user.username === loggedInUser)



    
    // const filteredUsers = unfollowedUser.filter(user => {
    //     const isSameState = CurrLoggedInUser.state !== null && CurrLoggedInUser !== undefined && CurrLoggedInUser === user.state;
    //     return isSameState;
    // })
 

    // const [randomUsers, setRandomUsers] = useState([])
    // const RandomIndex = 2

    // useEffect(()=> {
    //     const unfollowedUser = users.filter(user => {
        
    //         const isFollower = followers.some(follower => follower.user.username === user.username && loggedInUser === follower.follower)
    //         // return !isFollower && loggedInUser !== user.username;
    //         return !isFollower 
    //     })
    
    //     const newRandomUsers = []
    //     while (randomUsers.length < RandomIndex && unfollowedUser.length >0){
    //         const randomNumber = Math.floor(Math.random() * unfollowedUser.length)
    //         const randomUser = unfollowedUser.splice(randomNumber, 1)[0];
    //         newRandomUsers.push(randomUser)
    //     }
    //     setRandomUsers(newRandomUsers)
    
    // }, [])


    const [randomUsers, setRandomUsers] = useState([]);
    const [followLogic, setFollowLogic] = useState(0);
    const RandomIndex = 2;
    
    const followUser = () => {
        setFollowLogic(prevValue => prevValue + 1);

        setRandomUsers([]);
        console.log(`before Population `, {randomUsers})
        props['refresh']()
    };
    // setRandomUsers([]);

        



    useEffect(() => {    
        const unfollowedUser = users.filter(user => {
            const isFollower = followers.some(
                follower =>
                    follower.user.username === user.username &&
                    loggedInUser === follower.follower 
            );
            props['refresh']()
            return !isFollower && loggedInUser !== user.username ; 
        });

        const newRandomUsers = [];
        for (let i = 0; i < RandomIndex && unfollowedUser.length > 0; i++) {
            const randomNumber = Math.floor(Math.random() * unfollowedUser.length);
            // const randomNumber = 1
            const randomUser = unfollowedUser.splice(randomNumber, 1)[0];
            newRandomUsers.push(randomUser);
            props['refresh']()

        }

    
        setRandomUsers(newRandomUsers);
        props['refresh']()
    }, [auth, loggedInUser, users, followLogic, followers]);



    return (
        <div className="follower">
        <div className="follower-feeds">

            <div className="users-title">
                <h2 className="">Nearby Vendors</h2>
                <a href="/"> Refresh</a>
            </div> 
           
            <div className="followers-content">  
 
            { randomUsers && randomUsers.map((user, index) => (
                       <div key={`use-${index}`} className="followers-item">
                        <div className="followers-content-item">
                                <img src={`${BASE_URL}${user.profile.profileimg}`} className="profile-img-edit img-edit" alt='profile-img-edit'/>
                            <div className="users-info">
                                <div className="name-follow">
                                    <h5>
                                    {
                                     user.profile.marketname !== null && user.profile.marketname !== '' ? user.profile.marketname : user.username
                                    }                     
            
                                    </h5>                                         
                                    {
                                        isAuth ? <>
                                            <button className="follow-button" onClick={()=>{follow(user.username, loggedInUser); followUser()}} type="submit">Follow</button>
                                        </> :
                                            <>
            
                                            </>
                                    }
                                </div>
    
                                <span className="username" style={{fontSize: '13px'}}> @{user.username} </span>
                                <span className=""style={{fontSize: '13px'}} > 320k followers </span>
                            </div>
                        </div>
                        
                    </div>
                ))
            }
                
            </div>

        </div>
    </div>

    )
}

export default Followers;